import Entity from "./pagination/Entity";

export namespace User {
  export enum Role {
    ANONYMOUS = "ANONYMOUS",
    PATIENT = "PATIENT",
    DOCTOR = "DOCTOR",
    ADMIN = "ADMIN",
  }

  export interface Profile {
    phone: string
  }

  export interface PatientProfile extends Profile {
    socialWelfareNumber: string;
    birthdate: string;
    referentDoctorId: string;
  }

  export interface DoctorProfile extends Profile {
    rppsNumber: string;
    specialty: string;
    addressLine1: string;
    addressLine2?: string;
    zipCode: string;
    city: string;
    country: string;
  }

  export enum Status {
    DISABLED = "DISABLED",
    ENABLED = "ENABLED"
  }

  export class DTO implements Entity {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    preferredLanguage: string;
    shouldUpdatePassword: boolean;
    createdAt: number;
    createdBy?: string;
    role: Role;
    status: Status;
    profile?: Profile;
    lastLogin?: number;
    expireAt: number;
    reportStatuses?: any;

    constructor(id: string,
                firstName: string, lastName: string, email: string,
                title: string, preferredLanguage: string,
                shouldUpdatePassword: boolean, createdAt: number,
                profile: Profile | undefined,
                createdBy: string, role: User.Role, status: User.Status,
                expireAt: number, lastLogin: number, reportStatuses?: any) {
      this.id = id;
      this.firstName = firstName;
      this.lastName = lastName;
      this.email = email;
      this.title = title;
      this.preferredLanguage = preferredLanguage;
      this.shouldUpdatePassword = shouldUpdatePassword;
      this.createdAt = createdAt;
      this.createdBy = createdBy;
      this.profile = profile;
      this.role = role;
      this.status = status;
      this.expireAt = expireAt;
      this.lastLogin = lastLogin;
      this.reportStatuses = reportStatuses;
    }
  }

  export function prettyName(user?: User.DTO): string {
    if (!user) return "";
    return user.title + " " + user.firstName + " " + user.lastName;
  }
}