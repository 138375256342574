import {ListenableService, Listener} from "./serviceRepository";
import {ModalProps} from "../components/Pages/Modal";

export class ModalService implements ListenableService<ModalProps> {
  private _listeners: Array<Listener<ModalProps>> = [];
  private _currentModal: ModalProps = {
    isVisible: false,
    content: null,
    styleClass: undefined,
  };

  public setModal(props: ModalProps) {
    if (this._currentModal.isVisible && this._currentModal.onClose) {
      this._currentModal.onClose();
    }

    this._currentModal.content = props.content;
    this._currentModal.isVisible = props.isVisible;
    this._currentModal.onClose = props.onClose;
    this._notifyListeners();
  }

  public closeCurrentModal(): void {
    this.setModal({
      content: null,
      isVisible: false,
      onClose: this._currentModal.onClose
    })
  }

  private _notifyListeners(): void {
    for (let listener of this._listeners) {
      listener.onSubjectUpdate(this._currentModal);
    }
  }

  public addListener(listener: Listener<ModalProps>): void {
    this._listeners.push(listener);
  }

  public removeListener(listener: Listener<ModalProps>): void {
    let index = this._listeners.indexOf(listener);
    if (index === -1) return;
    this._listeners.splice(index, 1);
  }
}