import {ListenableService, Listener} from "./serviceRepository";

export enum WebcamStreamStatus {
  STARTED,
  PENDING_APPROVAL,
  REFUSED,
  STOPPED
}

export interface WebcamStream {
  stream: MediaStream | null
  isRecording: boolean
  properties?: boolean | MediaTrackConstraints
  blobs: Blob[]
  error?: string
}

export class WebcamService implements ListenableService<WebcamStream> {
  private _listeners: Array<Listener<WebcamStream>> = [];
  private _webcamStream: WebcamStream;
  private _recorder?: MediaRecorder;

  get webcamStream(): WebcamStream {
    return this._webcamStream;
  }

  constructor() {
    this._webcamStream = {
      properties: undefined,
      stream: null,
      isRecording: false,
      blobs: []
    }
  }

  /**
   * @param videoProperties
   */
  public startWebcamDisplay = (videoProperties: boolean | MediaTrackConstraints) => {
    this._webcamStream.blobs = [];

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({video: videoProperties})
        .then((stream) => {
          this._webcamStream.isRecording = false;
          this._webcamStream.stream = stream;
          this._webcamStream.properties = videoProperties;

          if (this._recorder) {
            this._recorder.stop();
          }
          this._notifyListeners();
        })
        .catch((e: Error) => {
          console.log("error:", e);
          this._webcamStream.error = e.name;
          this.stopRecording();
        })
    }
  };

  /**
   */
  public startRecording = () => {
    if (!this._webcamStream.stream) {
      console.error("Cannot start recording if steam is null.");
      return;
    }

    if (this._recorder) {
      this._recorder.stop();
    }

    this._recorder = new MediaRecorder(this._webcamStream.stream);

    this._recorder.ondataavailable = (event) => {
      if (event.data.size > 0) this.webcamStream.blobs.push(event.data);
      this._notifyListeners();
    }
    this._recorder.start();
    this._webcamStream.isRecording = true;
    this._notifyListeners();
  };

  public stopRecording() {
    console.error('stopRecording')
    if (this._webcamStream.stream instanceof MediaStream) {
      this._webcamStream.stream.getTracks().forEach(track => track.stop());
    }
    this._recorder?.stop();
    this._webcamStream.stream = null;
    this._webcamStream.properties = undefined;
    this._webcamStream.isRecording = false;
    this._notifyListeners();
  };

  _notifyListeners(): void {
    for (let listener of this._listeners) {
      listener.onSubjectUpdate(this._webcamStream);
    }
  }

  public addListener(listener: Listener<WebcamStream>): void {
    this._listeners.push(listener);
  }

  public removeListener(listener: Listener<WebcamStream>): void {
    let index = this._listeners.indexOf(listener);
    if (index === -1) return;
    this._listeners.splice(index, 1);
  }
}