import React from "react";
import {ServiceRepository} from "services/serviceRepository";
import {User} from "models/user";
import {toast} from "react-toastify";
import Axios, {AxiosError} from "axios";
import {FormCompiler} from "../../../utils/Form/FormCompiler";
import {AddPatientPayload} from "../../../services/userService";
import TextInput from "../../Shared/TextInput";
import {useTranslation} from "react-i18next";
import bLogo from "../../../assets/images/img.png";
import "./PatientCreate.scss";
import {useNavigate} from "react-router-dom";
import {Button} from "components/Shared/Button/Button";
import Color = Button.Color;
import {useSearchParam} from "react-use";

const PatientCreate: React.FC = (props) => {
  const userService = ServiceRepository.getInstance().userSvc;
  const navigate = useNavigate();
  const {t} = useTranslation();
  const compiler = new FormCompiler();
  const usrSvc = ServiceRepository.getInstance().userSvc;
  const doctorId = useSearchParam("doctorId");

  console.log("doctorId", doctorId);

  const handlePatientCreation = async (payload: AddPatientPayload): Promise<User.DTO> => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await usrSvc.addPatient(payload));
      } catch (e) {
        let message = "Echec de la creation du patient";

        if (Axios.isAxiosError(e)) {
          if ((e as AxiosError).response?.status === 409) {
            message = "Echec de la creation du patient : duplicat";
          }
        }
        toast(message, {
          autoClose: 10000,
          type: "error"
        });
        reject();
      }
    })
  }

  const submitForm = async () => {
    if (!compiler.checkFormValidity()) return;

    const payload: AddPatientPayload = compiler.compile([
      "firstName", "lastName", "title",
      "socialWelfareNumber", "email", "phone", "birthdate"
    ]);


    /***
     * if current user is a doctor, we assume that patient should be assigned to current user.
     * else attach it to currently selected doctor.
     ***/
    console.log(payload);
    if (userService.currentUser?.role === User.Role.DOCTOR) {
      payload.referentDoctorId = userService.currentUser.id;
    } else if (doctorId !== null) {
      payload.referentDoctorId = doctorId;
    } else {
      toast("Impossible de créer le patient : pas de docteur assigné.");
      return;
    }

    await handlePatientCreation(payload);
    toast("Patient créé");
    if (doctorId) {
      navigate(`/doctors/${doctorId}`, {
        replace: true
      });
    } else {
      navigate("/doctor", {
        replace: true
      });
    }
  }

  return  <div className={"patient-create"}>
    <div className={"header"}>
      <div className={"back-logo"}>
        <img onClick={() => navigate(-1)} src={bLogo} alt={"b2b blogo"}/>
      </div>
      <div className={`header-tab center selected`}>
        Création d'un patient
      </div>
    </div>
    <Button.Component customClass={"back-btn-list"} label={"← Retourner à la liste des patients"} onClick={() => navigate(-1)} color={Color.CLEAR}/>
    <table className={"form"}>
      <tbody>
      <tr>
        <td><label>Titre</label></td>
        <td>
          <TextInput
            ref={ref => ref && compiler.register(ref)}
            id={"title"}
            t={t}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"Mr"}
            defaultValue={"Mr"}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Prénom</label></td>
        <td>
          <TextInput
            ref={ref => ref && compiler.register(ref)}
            id={"firstName"}
            t={t}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"Louis"}
            defaultValue={"Louis"}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Nom</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"lastName"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"Pasteur"}
            defaultValue={"Pasteur"}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Date de naissance</label></td>
        <td>
          <TextInput
            ref={ref => ref && compiler.register(ref)}
            id={"birthdate"}
            required={true}
            errorMsg={"Champ obligatoire"}
            t={t}
            placeholder={"27/12/1822"}
            defaultValue={"27/12/1822"}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Téléphone</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"phone"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"06 XX XX XX XX"}
            defaultValue={"0664458146"}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Email</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"email"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"louis.pasteur@pasteur.fr"}
            defaultValue={"h.fritsch@pm.me"}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Numéro de sécurité sociale</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"socialWelfareNumber"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"194XXXX"}
            defaultValue={"12345678"}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>

      <tr className={"table-separator"}/>
      <tr>
        <td/>
        <td>
          <Button.Component label={"Créer le patient"} onClick={submitForm} color={Color.LIGHT_BLUE}/>
        </td>
        <td/>
      </tr>
      </tbody>
    </table>
  </div>
}

export default PatientCreate;