import React, {useCallback, useEffect, useState} from 'react';

import './Header.scss';
import './TabbedContainer.scss';
import {User} from "../../models/user";
import {ServiceRepository} from "../../services/serviceRepository";
import {Navigation} from "../../utils/routes";
import bLogo from "../../assets/images/img.png";
import menuIcon from "../../assets/images/menu_icon.png";
import {TabProps} from "./Tab";
import {useLocation, useNavigate} from "react-router-dom";
import {AdminMenu, DoctorMenu, PatientMenu} from "../Menu/Menu";

export interface ContainerProps {
  tab1: TabProps;
  tab2: TabProps;
}


const TabbedContainer: React.FC<React.PropsWithChildren<ContainerProps>> = (props: React.PropsWithChildren<ContainerProps>) => {
  const [userProfile, setUserProfile] = useState<User.DTO>();
  const [selectedTab, setSelectedTab] = useState<String>(props.tab1.title);
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userService = ServiceRepository.getInstance().userSvc;

  useEffect(() => {
    let params = Navigation.parseParams(location);
    if (params.has("showProfile")) {
      let showProfile = params.get("showProfile") as string;
      if (showProfile === "true") {
        setSelectedTab(props.tab2.title);
      } else {
        setSelectedTab(props.tab1.title);
      }
      navigate({search: ""})
    }

  }, [navigate, location, props.tab2.title, props.tab1.title])

  userService.addListener({
    onSubjectUpdate(sub?: User.DTO) {
      setUserProfile(sub);
    }
  })


  const getCurrentUserProfile = useCallback(async () => {
    try {
      setUserProfile(await userService.getCurrentUser());
    } catch (error) {
      console.error(error);
      setUserProfile(undefined);
    }
  }, [userService]);

  useEffect(() => {
    void getCurrentUserProfile();
  }, [getCurrentUserProfile]);

  return <div className={"tabbed-container"}>
    <div className={"header"}>
      <div className={"back-logo"}>
        <img onClick={() => navigate(-1)} src={bLogo} alt={"title button"}/>
        <img className={"menu-icon"} onClick={() => {
          console.log("open menu");
          setIsMenuOpened(true);
        }} src={menuIcon} alt={"menu"} />
      </div>
      {isMenuOpened && userProfile?.role === User.Role.PATIENT && <PatientMenu isMenuOpened={isMenuOpened} shouldCloseMenu={() => setIsMenuOpened(false)}/>}
      {isMenuOpened && userProfile?.role === User.Role.ADMIN && <AdminMenu isMenuOpened={isMenuOpened} shouldCloseMenu={() => setIsMenuOpened(false)}/>}
      {isMenuOpened && userProfile?.role === User.Role.DOCTOR && <DoctorMenu isMenuOpened={isMenuOpened} shouldCloseMenu={() => setIsMenuOpened(false)}/>}
      <div className={`header-tab center ${selectedTab === props.tab1.title ? "selected" : ""}`}
           onClick={() => {
             setSelectedTab(props.tab1.title);
             console.log("statement");
           }}
      >{props.tab1.title}
      </div>
      <div className={`header-tab right label-tab ${selectedTab === props.tab2.title ? "selected" : ""}`}
           onClick={() => {
             console.log("profile")
             setSelectedTab(props.tab2.title)
           }}>
        {
          props.tab2.icon ?
            <img className={"profile-logo"} src={selectedTab === props.tab2.title ? props.tab2.iconSelected : props.tab2.icon} alt={props.tab2.title}></img>
            :
            <span>{props.tab2.title}</span>
        }
      </div>
    </div>
    {selectedTab === props.tab1.title && props.tab1.content}
    {selectedTab === props.tab2.title && props.tab2.content}
  </div>;
}

export default TabbedContainer;
