import React, {useEffect, useMemo, useState} from "react";
import {Button} from "../Shared/Button/Button";
import "./ReportList.scss";
import {User} from "../../models/user";
import {Location} from "react-router-dom";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import List from "../Shared/List/List";
import ReportAdapter, {ReportAdapterListener} from "../../adapter/ReportAdapter";
import ReportsDataProvider from "../../adapter/ReportsDataProvider";
import {ServiceRepository} from "../../services/serviceRepository";
import {Dialog} from "./Dialog";
import {Report} from "../../models/report";
import {ModalProps} from "./Modal";
import {ReportDetails} from "../Main/Configuration/ReportDetails";

export interface ReportListProps {
  user: User.DTO
  location: Location
  navigate: NavigateFunction
}

let showCreateReportModal = (creationOk: () => {}, patient: User.DTO) => {
  let message: string = "";

  ServiceRepository.getInstance().modalSvc.setModal({
    isVisible: true,
    content: <Dialog
      title={"Demande de relevé"}
      customContent={<div className={"demande-releve"}><p>
        Afin de réaliser un relevé, vous devez envoyer une demande à<br/>votre patient afin qu'il le réalise depuis son appareil.
        Utilisez le champ ci-dessous pour lui écrire un message qui sera joint à la demande.
      </p>
        <textarea placeholder={"Votre message ici (facultatif)"} onChange={v => message = v.currentTarget.value}></textarea>
      </div>}
      leftBtn={{
        color: Button.Color.CLEAR,
        label: "Annuler",
        onClick: async () => {
          ServiceRepository.getInstance().modalSvc.closeCurrentModal();
        }}
      }
      rightBtn={{
        color: Button.Color.LIGHT_BLUE,
        label: "Envoyer au patient",
        onClick: async () => {
          try {
            await ServiceRepository.getInstance().reportSvc.requestReport(patient, message);
            showCreateReportConfirmationModal(creationOk);
          } catch (e) {
            console.error(e);
          }
        }
      }}
    />
  })
}

let showCreateReportConfirmationModal = (creationOk: () => {}) => {
  ServiceRepository.getInstance().modalSvc.setModal({
    isVisible: true,
    content: <Dialog
      title={"Demande de relevé"}
      text={"La demande a été envoyée avec succès !"}
      leftBtn={{
        color: Button.Color.LIGHT_BLUE,
        label: "Fermer",
        onClick: () => {
          creationOk();
          ServiceRepository.getInstance().modalSvc.setModal({isVisible: false, content: null});
        }
      }}
    />
  })
}

export const ReportList: React.FC<ReportListProps> = (props: ReportListProps) => {
  const [reports, setReports] = useState<Report.DTO[]>([]);
  const [unreviewedReportCount, setUnreviewedReportCount] = useState<number>(0);

  const dataProvider = useMemo(() => {
    return new ReportsDataProvider(ServiceRepository.getInstance().reportSvc, props.user.id, {
      onUpdate(reports: Report.DTO[]) {
        setReports(reports);
        setUnreviewedReportCount(reports.filter(report => report.status === Report.Status.TO_REVIEW).length);
      }
    });
  }, [props.user.id]);
  const [modal, setModal] = useState<ModalProps | undefined>(undefined);

  const adapterListener = useMemo<ReportAdapterListener>(() => {
    return {
      rowSelected(obj: Report.DTO) {
        if (modal) return;

        setModal({
          isVisible: true,
          onClose: () => {
            setModal(undefined);
          },
          content: <Dialog title={"Détail du relevé"}
                           customContent={<ReportDetails report={obj} shouldReload={() => dataProvider.load()}/>}/>
        });
      }
    }
  }, [dataProvider, modal]);
  const adapter = useMemo(() => {
    return new ReportAdapter(dataProvider, adapterListener);
  }, [dataProvider, adapterListener]);

  useEffect(() => {
    adapter.onUpdate(reports);
  }, [reports])

  if (modal) ServiceRepository.getInstance().modalSvc.setModal(modal);

  return <div className={"report-list"}>
    <p className={"column-header"}>{reports.length} relevé{reports.length > 0 && 's'} {unreviewedReportCount > 0 && <span className={"pending"}>({unreviewedReportCount} en attente)</span>}</p>
    <List adapter={adapter} dataProvider={dataProvider} hideHeader={true}/>
    <Button.Component label={"Créer un relevé"} color={Button.Color.LIGHT_BLUE} onClick={() => {
      showCreateReportModal(() => dataProvider.load(), props.user);
    }}/>
  </div>
}