import React from "react";
import {Button} from "../Shared/Button/Button";
import "./DoctorList.scss";
import {ServiceRepository} from "../../services/serviceRepository";
import {User} from "../../models/user";
import List from "../Shared/List/List";
import UsersDataProvider from "../../adapter/UsersDataProvider";
import DoctorsAdapter, {DoctorsAdapterListener} from "../../adapter/DoctorsAdapter";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import searchImg from "../../assets/images/search.svg";

interface DoctorListProps {
  navigate: NavigateFunction
}

interface DoctorListState {
  doctors?: Array<User.DTO>,
  dataProvider: UsersDataProvider,
  adapter: DoctorsAdapter
}

export default class DoctorList extends React.Component<DoctorListProps, DoctorListState> implements DoctorsAdapterListener {
  private readonly _userSvc = ServiceRepository.getInstance().userSvc;
  private _isMounted = false;

  constructor(props: DoctorListProps, state: DoctorListState) {
    super(props, state);
    const dataProvider = new UsersDataProvider(this._userSvc, User.Role.DOCTOR, undefined);

    this.state = {
      doctors: undefined,
      dataProvider: dataProvider,
      adapter: new DoctorsAdapter(dataProvider, this, false)
    }
  }

  rowSelected(obj: User.DTO): void {
    this.props.navigate(`/doctors/${obj.id}`);
  }

  componentDidMount() {
    this._isMounted = true;
    this._userSvc.getDoctors().then(response => {
      this.setState({
        doctors: response.data
      })
    }).catch(e => {
      console.error(e)
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return <div className={"doctor-list"}>
      <div className={"doctor-list-header"}>
        <div className={"search-box"}>
          <input placeholder={"Rechercher un docteur"}/>
          <img src={searchImg} alt='loupe'/>
        </div>
        <Button.Component
          color={Button.Color.LIGHT_BLUE}
          label={"CRÉER UN DOCTEUR"} onClick={() => this.props.navigate("/doctors/create", {replace: true})}/>
      </div>
      <List adapter={this.state.adapter} dataProvider={this.state.dataProvider}/>
    </div>
  }
}