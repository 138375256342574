import React from "react";
import {Button} from "../Shared/Button/Button";
import "./PatientProfileAsDoctor.scss";
import {ServiceRepository} from "../../services/serviceRepository";
import {User} from "../../models/user";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {Location} from "react-router-dom";
import {toast} from "react-toastify";

interface PatientProfileAsDoctorProps {
  location: Location
  navigate: NavigateFunction
  user?: User.DTO
}

interface PatientProfileAsDoctorState {
  profile?: User.PatientProfile,
  doctor?: User.DTO,
  doctorProfile?: User.DoctorProfile
}

export default class PatientProfileAsDoctor extends React.Component<PatientProfileAsDoctorProps, PatientProfileAsDoctorState>{
  private readonly _userSvc = ServiceRepository.getInstance().userSvc;

  constructor(props: PatientProfileAsDoctorProps, state: PatientProfileAsDoctorState) {
    super(props, state);

    this.state = {
      profile: undefined
    };
  }

  componentDidMount() {
    super.componentDidMount?.();
    this._refreshUser();
  }

  componentWillUnmount() {
    super.componentWillUnmount?.();
  }

  componentDidUpdate(prevProps: Readonly<PatientProfileAsDoctorProps>, prevState: Readonly<PatientProfileAsDoctorState>, snapshot?: any) {
    console.log("did update", this.props.user);
    if (prevProps.user?.id !== this.props.user?.id) {
      this._refreshUser();
    }
  }

  private _refreshUser() {
    let pathname = this.props.location.pathname.split("/");
    if (pathname.length === 0) this.props.navigate(-1);

    let profile = this.props.user?.profile as User.PatientProfile;

    this.setState({
      profile: profile
    }, () => {
      this._refreshDoctor();
    })
  }

  private _refreshDoctor() {
    if (this.state.profile?.referentDoctorId) {
      this._userSvc.getUser(this.state.profile?.referentDoctorId).then(doctor => {
        const doctorProfile: User.DoctorProfile | undefined = doctor?.role === User.Role.DOCTOR ? doctor?.profile as User.DoctorProfile : undefined;
        this.setState({
          doctor: doctor,
          doctorProfile: doctorProfile
        })
      })
    }

  }

  updateUserProfile = () => {
    this.props.navigate(`/patients/edit/${this.props.user?.id}`);
  }

  deleteUser = async () => {
    const userId = this.props.user?.id;
    if (!userId || !window.confirm("Vous allez supprimer l'utilisateur. Cette action est irréversible.")) return;

    await this._userSvc.deleteProfile(userId);
    toast('Utilisateur supprimé.');
    this.props.navigate("/doctor");
  }

  render() {
    const user = this.props.user;
    const profile = this.state.profile;
    const doctor = this.state.doctor;
    const doctorProfile = this.state.doctorProfile;

    return <div className={"patient-profile-as-doctor"}>
      <p className={"column-header"}>{User.prettyName(user)}<span onClick={this.updateUserProfile}>modifier le profil</span></p>
      <table>
        <tbody>
        <tr><td><br/></td></tr>
        <tr>
          <td>Titre :</td>
          <td>{user?.title}</td>
        </tr>
        <tr>
          <td>Nom de famille :</td>
          <td>{user?.lastName}</td>
        </tr>
        <tr>
          <td>Prénom(s) :</td>
          <td>{user?.firstName}</td>
        </tr>
        <tr>
          <td>Date de naissance :</td>
          <td>{profile?.birthdate}</td>
        </tr>
        <tr><td><br/></td></tr>
        <tr>
          <td>Numéro de sécurité sociale :</td>
          <td>{profile?.socialWelfareNumber}</td>
        </tr>
        <tr>
          <td>Médecin traitant :</td>
          <td>{User.prettyName(doctor)} (n RPPS : {doctorProfile?.rppsNumber})</td>
        </tr>
        <tr><td><br/></td></tr>
        <tr>
          <td>Adresse mail :</td>
          <td>{user?.email}</td>
        </tr>
        <tr>
          <td>Numéro de téléphone :</td>
          <td>{user?.profile?.phone ?? "À définir"}</td>
        </tr>
        <tr>
          <td>Identifiant :</td>
          <td>{user?.id}</td>
        </tr>
        </tbody>
      </table>

      <Button.Component label={"SUPPRIMER LE PATIENT"}
                        color={Button.Color.RED}
                        onClick={() => this.deleteUser()}/>
    </div>
  }
}