import React from "react";
import "./Statement.scss"
import {Button} from "../Shared/Button/Button";
import {WebcamStream} from "../../services/webcamService";
import {ServiceRepository} from "../../services/serviceRepository";
import Statement from "./Statement";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {Report} from "../../models/report";
import {ReportController} from "../../services/reportController";

interface StatementSurveyProps {
  navigate: NavigateFunction
  report: Report.DTO
  controller: ReportController
}

export enum FormSubmissionState {
  READY,
  SENDING,
  SUCCESS,
  ERROR
}

interface StatementSurveyState {
  webcamStream: WebcamStream,
  uploadProgress: number,
  formValues: any,
  formSubmissionState: FormSubmissionState
}

export default class StatementSurvey extends React.Component<StatementSurveyProps, StatementSurveyState> {
  private readonly _webcamService = ServiceRepository.getInstance().webcamSvc;
  private readonly _reportService = ServiceRepository.getInstance().reportSvc;
  private _unmounted: boolean = false;

  constructor(props: StatementSurveyProps, state: StatementSurveyState) {
    super(props, state);

    this.state = {
      webcamStream: this._webcamService.webcamStream,
      uploadProgress: 0,
      formValues: {context: "at_rest"},
      formSubmissionState: FormSubmissionState.READY
    }
  }

  componentDidMount() {
    this._unmounted = false;
    this._webcamService.addListener(this);

    window.addEventListener('beforeunload', this.alertUser)

    this.props.controller.progressListener = {
      onProgress: progressEvent => {
        console.log(progressEvent);
        this.setState({
          uploadProgress: Math.ceil(progressEvent.loaded / progressEvent.total * 100)
        })
      }
    }
    this.props.controller.startUpload();
  }

  componentWillUnmount() {
    this._unmounted = true;
    this._webcamService.removeListener(this);

    window.removeEventListener('beforeunload', this.alertUser)
  }

  alertUser = (event:any) => {
    event.returnValue = ""
  }

  private _showStatementModal(webcamStream: WebcamStream) {
    ServiceRepository.getInstance().modalSvc.setModal({
      isVisible: true,
      styleClass: "statement-modal",
      content: <Statement report={this.props.report} navigate={this.props.navigate} webcamStream={webcamStream}/>
    });
  }

  onSubjectUpdate(sub?: WebcamStream): void {
    if (this._unmounted || !sub) return;

    if (sub.stream) {
      this._showStatementModal(sub);
      this.setState({
        webcamStream: sub
      })
    }
  }

  private _submitForm = () => {
    const compiledForm = this.state.formValues;
    this.setState({
      formSubmissionState: FormSubmissionState.SENDING
    });
    console.log("compiledForm", compiledForm);

    this._reportService.editReport(this.props.report.id, compiledForm).then(r => {
      this.setState({
        formSubmissionState: FormSubmissionState.SUCCESS
      }, () => {
        setTimeout(() => {
          ServiceRepository.getInstance().modalSvc.setModal({isVisible: false, content: null});
          this.props.navigate(ServiceRepository.getInstance().policySvc.policy.getLandingURL() + "?showGreetings=true");
        }, 1000)
      })
    }).catch(e => {
      console.error(e);
      this.setState({
        formSubmissionState: FormSubmissionState.ERROR
      });
    });
  }

  render() {
    let submitBtnLabel: string;
    let submitBtnColor: Button.Color;
    let submitBtnEnabled: Boolean;

    switch (this.state.formSubmissionState) {
      case FormSubmissionState.READY:
        submitBtnEnabled = true;
        submitBtnLabel = "Valider mes réponses";
        submitBtnColor = Button.Color.DARK_BLUE;
        break;
      case FormSubmissionState.SENDING:
        submitBtnEnabled = false;
        submitBtnLabel = "Sauvegarde en cours";
        submitBtnColor = Button.Color.CLEAR;
        break;
      case FormSubmissionState.SUCCESS:
        submitBtnEnabled = false;
        submitBtnLabel = "Réponses sauvegardées";
        submitBtnColor = Button.Color.LIGHT_BLUE;
        break;
      case FormSubmissionState.ERROR:
        submitBtnEnabled = false;
        submitBtnLabel = "Erreur lors de la sauvegarde";
        submitBtnColor = Button.Color.RED;
        break;

    }

    return <div className={"statement-survey-modal"}>
      <h1>Relevé terminé</h1>
      <div className={"statement-survey-modal-form"}>
        {this.state.uploadProgress >= 0 && this.state.uploadProgress < 100 && <p className={"upload-monitor"}>Téléversement de la capture en cours ({this.state.uploadProgress}%)</p>}
        {this.state.uploadProgress >= 100 && <p className={"upload-monitor upload-finished"}>Téléversement de la capture terminé !
        </p>}

        <p>Votre relevé est terminé et va être analysé puis envoyé à votre médecin référent. Vous pouvez répondre à quelques questions ci-après afin de donner plus d'éléments à votre médecin.</p>

        <p>Dans quel contexte d'activité avez-vous effectué ce relevé ?</p>
        <select name={"context"} onChange={event => {
          let selectedOptions: Array<any> = [];
          let length = event.currentTarget.selectedOptions.length;
          for (let i = 0; i < length; i++) {
            let id = event.currentTarget.selectedOptions.item(i)?.id;
            if (id) selectedOptions.push(id);
          }

          this.setState({
            formValues: {...this.state.formValues, context: selectedOptions}
          })
        }} placeholder={"Choisissez un contexte"}>
          <option value={"Au repos"} id={"at_rest"}>Au repos</option>
          <option value={"Durant un effort"} id={"during_effort"}>Durant un effort</option>
          <option value={"Apres un effort"} id={"after_effort"}>Apres un effort</option>
        </select>

        <p>Avez-vous ressenti des douleurs inhabituelles lors du relevé ?</p>
        <label>
          <input type={"checkbox"}
                 onChange={e => this.setState({formValues: {
                     ...this.state.formValues,
                     chest_pain: e.currentTarget.checked
                   }})}
                 name={"Poitrine"}/>
          Poitrine
        </label>
        <label>
          <input type={"checkbox"}
                 onChange={e => this.setState({formValues: {
                     ...this.state.formValues,
                     belly_pain: e.currentTarget.checked
                   }})}
                 name={"Ventre"}/>
          Ventre
        </label>
        <label>
          <input type={"checkbox"}
                 onChange={e => this.setState({formValues: {
                     ...this.state.formValues,
                     headache_pain: e.currentTarget.checked
                   }})}
                 name={"Céphalées"}/>
          Céphalées
        </label>
        <label>
          <input type={"checkbox"}
                 onChange={e => this.setState({formValues: {
                     ...this.state.formValues,
                     left_arm_pain: e.currentTarget.checked
                   }})}
                 name={"Bras gauche"}/>
          Bras gauche
        </label>
        <label>
          <input type={"checkbox"}
                 onChange={e => this.setState({formValues: {
                     ...this.state.formValues,
                     right_arm_pain: e.currentTarget.checked
                   }})}
                 name={"Bras droit"}/>
          Bras droit
        </label>
        <label>
          <input type={"checkbox"}
                 onChange={e => this.setState({formValues: {
                     ...this.state.formValues,
                     legs_pain: e.currentTarget.checked
                   }})}
                 name={"Jambes"}/>
          Jambes
        </label>

        <p></p>
        <p>Avez-vous ressenti des symptômes inhabituels lors du relevé ?</p>
        <label>
          <input type={"checkbox"}
                 onChange={e => this.setState({formValues: {
                     ...this.state.formValues,
                     palpitations: e.currentTarget.checked
                   }})}
                 name={"Palpitations"}/>
          Palpitations
        </label>
        <label>
          <input type={"checkbox"}
                 onChange={e => this.setState({formValues: {
                     ...this.state.formValues,
                     fatigue: e.currentTarget.checked
                   }})}
                 name={"Fatigue"}/>
          Fatigue
        </label>

        <span className={"label-symptoms"}>Si vous le souhaitez, vous pouvez décrire ces symptomes plus en detail ci-dessous :</span>
        <textarea placeholder={"Decrivez vos symptomes ..."} onChange={e => this.setState({formValues: {
            ...this.state.formValues,
            details: e.currentTarget.value
          }})}/>
      </div>
      <div className={"statement-survey-modal-buttons"}>
        <Button.Component
          disabled={!submitBtnEnabled}
          color={submitBtnColor}
          label={submitBtnLabel}
          onClick={() => {
            this._submitForm();
          }}/>
        {/*<label className={"restart-hint"}>Quelque chose ne s'est pas passé comme prévu ?</label>*/}
        {/*<Button.Component label={"Relancer un relevé"} color={Button.Color.CLEAR} onClick={() => {*/}
        {/*  this._showStatementModal(this.state.webcamStream);*/}
        {/*}}*/}
        {/*/>*/}
      </div>
    </div>
  }

  onProgress(progressEvent: ProgressEvent): void {
    console.info("#######progress")
  }
}