import React, {useEffect, useState} from "react";
import './UserDetails.scss'
import {useNavigate, useParams} from "react-router-dom";
import DoctorProfileAsAdmin from "../../Pages/DoctorProfileAsAdmin";
import PatientList from "../../Pages/PatientList";
import {User} from "../../../models/user";
import {ServiceRepository} from "../../../services/serviceRepository";
import bLogo from "../../../assets/images/img.png";

const DoctorDetails: React.FC = () => {
  const [doctor, setDoctor] = useState<User.DTO | undefined>(undefined);
  let params = useParams()
  let navigate = useNavigate()

  useEffect(() => {
    const usrSvc = ServiceRepository.getInstance().userSvc;
    if (params.doctorId !== undefined) usrSvc.getUser(params.doctorId).then(setDoctor);
  }, [params.doctorId]);

  return <div className={'user-details-page'}>
    {
      doctor &&
      <>
        <div className={"doctor-edit"}>
          <div className={"header"}>
            <div className={"back-logo"}>
              <img onClick={() => navigate(-1)} src={bLogo} alt={"b2b blogo"}/>
            </div>
            <div className={`header-tab center selected`}>
              Édition d'un docteur
            </div>
          </div>
          <DoctorProfileAsAdmin navigate={navigate} doctor={doctor} />
          <PatientList doctorId={params.doctorId} navigate={navigate}/>
        </div>
      </>
    }
  </div>;
}

export default DoctorDetails;