import React, {useState} from "react";
import {Dialog} from "./Dialog";
import {Button} from "../Shared/Button/Button";
import {ServiceRepository} from "../../services/serviceRepository";
import './DeleteAccountModal.scss';
import {toast} from "react-toastify";
import {User} from "../../models/user";
import {useNavigate} from "react-router-dom";

/**
 * Verify password strength.
 * Should contain between 8 and 32 characters.
 * Should contain at least one lowercase, one uppercase, one digit and a special character.
 * @param password
 */
type DeleteAccountModalProps = {
  relatedUser: User.DTO
}

export const DeleteAccountModal: React.FC<DeleteAccountModalProps> = (props: DeleteAccountModalProps) => {
  const [confirmation, setConfirmation] = useState<string>("");
  const [confirmationError, setConfirmationError] = useState<boolean>(false);
  const navigate = useNavigate();

  const deleteAccount = async () => {
    //TODO : implement
    let confirmationError = confirmation !== props.relatedUser.email;

    setConfirmationError(confirmationError);

    if (confirmationError) return;

    await ServiceRepository.getInstance().userSvc.deleteProfile(props.relatedUser.id);
    ServiceRepository.getInstance().modalSvc.closeCurrentModal();
    toast("Utilisateur supprimé avec succès.")
    navigate(-1);
  };

  return <Dialog
    title={"Suppression du docteur"}
    leftBtn={{label: "Annuler", color: Button.Color.CLEAR, onClick: () => ServiceRepository.getInstance().modalSvc.closeCurrentModal()}}
    rightBtn={{label: "Supprimer", color: Button.Color.RED, onClick: () => deleteAccount()}}
    customContent={<div className={"delete-account-form"}>
      <div className={"confirmation-container"}>
        <input placeholder="Confirmez l'email du docteur." type="text"
               onChange={e => setConfirmation(e.target.value)}/>
      </div>
      {confirmationError && <p className={"field-error"}>La confirmation ne correspond pas.</p>}
    </div>}
  />
}
