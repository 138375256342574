import i18next from 'i18next'
//
// i18next.use(initReactI18next)
//     .use(ChainedBackend).init({
//     lng: 'en',
//     fallbackLng: 'en',
//     preload: ['en'],
//     ns: ['translation'],
//     defaultNS: 'translation',
//     backend: {
//         backends: [
//             HttpBackend
//         ],
//         backendOptions: [
//             {
//                 loadPath: `${BASE_URL}${URLs.API.TRANSLATIONS}?format=i18n&type=INTERFACE&lang={{lng}}`
//             }
//         ]
//     }
// })
// i18next.use(initReactI18next)
//     .use(ChainedBackend).init({
//     lng: 'en',
//     fallbackLng: 'en',
//     preload: ['en'],
//     ns: ['translation'],
//     defaultNS: 'translation',
//     backend: {
//         backends: [
//             HttpBackend
//         ],
//         backendOptions: [
//             {
//                 loadPath:
//                     `${BASE_URL}${URLs.API.TRANSLATIONS}?format=i18n&type=INTERFACE&lang=EN`
//             }
//         ]
//     }
// })

export default i18next;