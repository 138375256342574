import React, {useState} from "react";
import {Dialog} from "./Dialog";
import {Button} from "../Shared/Button/Button";
import {ServiceRepository} from "../../services/serviceRepository";
import './ChangePasswordModal.scss';
import eye from "../../assets/images/eye.svg";
import {toast} from "react-toastify";

/**
 * Verify password strength.
 * Should contain between 8 and 32 characters.
 * Should contain at least one lowercase, one uppercase, one digit and a special character.
 * @param password
 */
export function isPasswordStrongEnough(password: string): boolean {
  //redundant check as typescript is not strict enough.
  if (!password) return false;

  const regexPassword = /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,32}$/;

  return !!password.match(regexPassword);
}

export const ChangePasswordModal: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>("");
  const [confirmationError, setConfirmationError] = useState<boolean>(false);
  const [requirementsError, setRequirementError] = useState<boolean>(false);

  const savePassword = async () => {
    let confirmationError = newPassword !== newPasswordConfirmation;
    let requirementError = !isPasswordStrongEnough(newPassword);

    setRequirementError(requirementError);
    setConfirmationError(confirmationError);

    if (requirementError || confirmationError) return;

    await ServiceRepository.getInstance().userSvc.changePassword(newPassword);
    ServiceRepository.getInstance().modalSvc.closeCurrentModal();
    toast("Mot de passe modifié avec succès.")
  };

  return <Dialog
    title={"Modification du mot de passe"}
    leftBtn={{label: "Annuler", color: Button.Color.CLEAR, onClick: () => ServiceRepository.getInstance().modalSvc.closeCurrentModal()}}
    rightBtn={{label: "Sauvegarder", color: Button.Color.LIGHT_BLUE, onClick: () => savePassword()}}
    customContent={<div className={"password-change-form"}>
      <p className={"password-requirements"}>
        Le nouveau mot de passe doit :<br/>
        - faire un minimum de huit caractères<br/>
        - comporter une lettre minuscule<br/>
        - comporter une lettre majuscule<br/>
        - comporter un caractère spécial (_#+-=.)<br/>
        - comporter un chiffre</p>
      <div className={"password-container"}>
        <input placeholder="Saisissez votre nouveau mot de passe" type={showPassword ? "text" : "password"}
               autoComplete="current-password" onChange={e => setNewPassword(e.target.value)}/>
        <img className={"password-eye"} onClick={() => setShowPassword(!showPassword)} alt={"show-password"} src={eye}/>
      </div>
      <div className={"password-container"}>
        <input placeholder="Confirmez votre nouveau mot de passe" type={showPassword ? "text" : "password"}
               autoComplete="current-password" onChange={e => setNewPasswordConfirmation(e.target.value)}/>
        <img className={"password-eye"} onClick={() => setShowPassword(!showPassword)} alt={"show-password"} src={eye}/>
      </div>
      {requirementsError && <p className={"field-error"}>
        Le nouveau mot de passe ne correspond aux exigences.</p>}
      {confirmationError && <p className={"field-error"}>Le nouveau mot de passe ainsi<br/>que la confirmation ne correspondent pas.</p>}
    </div>}
  />
}
