import React from "react";
import "./StartStatement.scss"
import {Button} from "components/Shared/Button/Button";

interface StartStatementProps {
  goToStatement: () => void,
}

export default class StartStatement extends React.Component<StartStatementProps, {}> {
  render() {
    return <div className={"statement-modal start"}>
      <div className={"statement-modal-title start"}>
        <span>Préparation de la mesure</span>
      </div>
      <div className={"progress-bar"} style={{width: `0%`}}/>
      <span className={"tips"} style={{color: "white", textAlign: "left", paddingLeft: "2em"}}>
        • La permission d'utiliser votre caméra va être demandée.<br/>
        • L'utilisation de la caméra cessera dès la fin de la mesure.<br/>
        • Bougez le moins possible pour assurer une mesure précise.<br/>
        • Maintenez votre visage dans le cercle bleu.<br/>
        • Retirez vos lunettes, accessoires, relevez vos cheveux.<br/>
        • Tenez vous seul(e) face à la caméra.<br/>
        • Un questionnaire sera proposé à la fin du relevé pour décrire comment s'est passée la mesure.</span><br/>
      <Button.Component type={Button.Type.NORMAL} label={"Démarrer la mesure"} color={Button.Color.LIGHT_BLUE} align={Button.Align.CENTER} onClick={() =>
        this.props.goToStatement()
      }/><br/>
    </div>
  }
}