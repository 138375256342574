import {User} from 'models/user';
import React, {useEffect, useMemo, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ServiceRepository} from "../../../services/serviceRepository";
import List from "../../Shared/List/List";
import UsersDataProvider from "../../../adapter/UsersDataProvider";
import UserAdapter, {UserAdapterListener} from "../../../adapter/UserAdapter";
import './UsersList.scss'
import Dialog from "../../Shared/Dialog";
import {toast} from "react-toastify";

type UsersListProps = {
  role: User.Role;
  referentUserId?: string;
}

const UsersList: React.FC<UsersListProps> = (props: UsersListProps) => {
  const [dataProvider] = useState(new UsersDataProvider(ServiceRepository.getInstance().userSvc, props.role, props.referentUserId));
  const [selectedUser, setSelectedUser] = useState<User.DTO | undefined>(undefined);
  const navigate = useNavigate();

  const listener: UserAdapterListener = useMemo(() => {
    return {
      rowSelected(obj: User.DTO): void {
        navigate(`/${obj.role === User.Role.PATIENT ? 'patients' : 'doctors'}/${obj.id}`, {replace: true});
      },
      removeClicked(user: User.DTO): void {
        setSelectedUser(user);
      }
    }
  }, [navigate]);

  const adapter = useMemo(
    () => new UserAdapter(dataProvider, listener, props.role === User.Role.PATIENT),
    [dataProvider, listener, props.role]
  );

  useEffect(() => {
    dataProvider.referentUserId = props.referentUserId;
  }, [dataProvider, props.referentUserId])

  const deleteUser = async (userToDelete: User.DTO) => {
    const usrSvc = ServiceRepository.getInstance().userSvc;

    toast("Suppression de l'utilisateur");
    try {
      await usrSvc.removeUser(userToDelete);
      setSelectedUser(undefined);
      dataProvider.load();
      toast('Utilisateur supprimé');
    } catch (e) {
      toast('Echec de la suppression', {
        type: "error"
      });
    }
  };

  const referentIdSearchParam = (props.referentUserId ? `?doctorId=${props.referentUserId}` : "")

  return (
    <div className={"users-page"}>

      <List adapter={adapter} dataProvider={dataProvider}/>

      {props.role === User.Role.PATIENT && <Link className={'centered-button'} to={`/patients/create/${referentIdSearchParam}`}>
        <button className={"button light"}>{"Ajouter un patient"}</button>
      </Link>}

      {props.role === User.Role.DOCTOR && <Link className={'centered-button'} to={"/doctors/create"}>
        <button className={"button light"}>{"Ajouter un docteur"}</button>
      </Link>}


      <Dialog
        visible={!!selectedUser}
        dismiss={() => setSelectedUser(undefined)}
        title={'usersListDeletePatientDialogTitle'}
        subtitle={'usersListDeletePatientDialogSubtitle'}
        positiveButton={{
          label: 'usersListDeletePatientDialogPositiveBtn', onClick: () => {
            if (selectedUser) deleteUser(selectedUser);
          }
        }}
      />
    </div>
  );
};

export default UsersList;