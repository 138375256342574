import {DataProvider, DataProviderListener} from "../models/provider/DataProvider";
import {PatientAPIPagination} from "./PatientAPIPagination";
import {PaginationController} from "../models/pagination/PaginationController";
import {ReportService} from "../services/reportService";
import {Report} from "../models/report";

export default class ReportsDataProvider extends DataProvider<Report.DTO, PatientAPIPagination>{
    private readonly _reportSvc: ReportService;
    private _userId?: string;

    /**
     * constructor
     * @param reportSvc
     * @param userId
     * @param listener
     */
    public constructor(reportSvc: ReportService, userId?: string, listener?: DataProviderListener<Report.DTO>) {
        super(new PaginationController<PatientAPIPagination>(new PatientAPIPagination()), listener);

        this._reportSvc = reportSvc;
        this._userId = userId;
    }

    set userId(value: string | undefined) {
      this._userId = value;
      this.load();
    }

    /**
     * load
     * @returns {Promise<Report.DTO[]>}
     */
    async load() : Promise<Report.DTO[]> {
        if (!this._userId) return [];
        const reports = await this._reportSvc.getReportsOfUser(this._userId);

        this.notifySubscribers(reports.data);

        return reports.data;
    }
}