import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import App from "./components/App";
import {enableMapSet} from "immer";

import './utils/i18n/i18n';
import {BrowserRouter} from "react-router-dom";

export const DEFAULT_LOCALE = "en"; // TODO move to DefaultValues.ts ?

window.addEventListener('contextmenu', function (e) {
  e.preventDefault();
});

enableMapSet();

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<></>}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
