import React from "react";
import ListAdapter, {AdapterListener} from "../components/Shared/List/ListAdapter";
import {PatientAPIPagination} from "./PatientAPIPagination";
import {Column} from "../components/Shared/List/Column";
import UsersDataProvider from "./UsersDataProvider";
import {User} from "../models/user";
import {Report} from "../models/report";

export interface PatientsAdapterListener extends AdapterListener<User.DTO> {
  removeClicked: (user: User.DTO) => void;
}

export default class PatientsAdapter extends ListAdapter<User.DTO, PatientsAdapterListener, PatientAPIPagination> {
  private _showRemove: boolean | undefined;

  /**
   * constructor
   * @param patientsProvider
   * @param listener
   * @param showRemove
   */
  constructor(patientsProvider: UsersDataProvider, listener?: PatientsAdapterListener, showRemove?: boolean) {
    super();

    this.addColumn(new Column('Patient', "lastName", this._name));
    this.addColumn(new Column('N° SS', "socialWelfareNumber", this._socialWelfareNumber));
    this.addColumn(new Column('Relevés', "reports", this._reports));

    this.init(
      "Il n'y a pas encore de patient.",
      patientsProvider,
      [],
      listener
    );

    this._showRemove = showRemove;

    this.criteria.unshift({
      relatedFilterKey: PatientAPIPagination.GRANT_TYPE_FILTER,
      translationKey: "all",
      value: "all"
    });
  }

  /**
   * _firstName
   * @returns {any}
   * @private
   * @param patient
   */
  private _name = (patient: User.DTO): JSX.Element => {
    return <p className={"blue-hover"}>{patient.lastName + ' ' + patient.firstName}</p>
  };

  /**
   * _socialWelfareNumber
   * @returns {any}
   * @private
   * @param patient
   */
  private _socialWelfareNumber = (patient: User.DTO): JSX.Element => {
    return <p>{(patient.profile as User.PatientProfile | undefined)?.socialWelfareNumber || "N/A"}</p>
  };

  /**
   * _reports
   * @returns {any}
   * @private
   * @param patient
   */
  private _reports = (patient: User.DTO): JSX.Element => {
    if (!patient.reportStatuses) return <p className={"no-report-yet"}>pas encore de relevé</p>

    const toReviewCount = patient.reportStatuses[Report.Status.TO_REVIEW];
    const okCount = patient.reportStatuses[Report.Status.OK];

    const getToReview = () => {
      if (toReviewCount === 1) {
        return <span className={"one-new-report"}>1 nouveau</span>;
      }
      if (toReviewCount > 1) {
        return <span className={"several-new-report"}>{toReviewCount} nouveaux</span>;
      }
      return undefined;
    }

    const getOk = () => {
      if (okCount === 1) {
        return <span className={"old-report"}>1 ancien</span>;
      }
      if (okCount > 1) {
        return <span className={"old-report"}>{okCount} anciens</span>;
      }
      return undefined;
    }

    if (toReviewCount > 0 && okCount === 0) {
      return <p>{getToReview()}</p>
    }

    if (toReviewCount === 0 && okCount > 0) {
      return <p>{getOk()}</p>
    }

    if (toReviewCount > 0 && okCount > 0) {
      return <p>{getToReview()}, {getOk()}</p>
    }

    return <p>Pas encore de relevé</p>
  };
}