import React from "react";
import {ServiceRepository} from "services/serviceRepository";
import {User} from "models/user";
import {toast} from "react-toastify";
import Axios, {AxiosError} from "axios";
import {FormCompiler} from "../../../utils/Form/FormCompiler";
import {AddDoctorPayload} from "../../../services/userService";
import TextInput from "../../Shared/TextInput";
import {useTranslation} from "react-i18next";
import "./DoctorCreate.scss";
import {useNavigate} from "react-router-dom";
import TabbedContainer from "../../Header/TabbedContainer";
import AdminProfile from "../../Pages/AdminProfile";
import {Button} from "components/Shared/Button/Button";

const DoctorCreateForm: React.FC = () => {
  const userService = ServiceRepository.getInstance().userSvc;
  const navigate = useNavigate();
  const {t} = useTranslation();

  const compiler = new FormCompiler();

  const handleDoctorsCreation = async (payload: AddDoctorPayload): Promise<User.DTO> => {
    return new Promise(async (resolve, reject) => {
      try {
        let doctors = await userService.addDoctor(payload);

        resolve(await userService.editUser(doctors.id, {
          role: User.Role.DOCTOR
        }));
      } catch (e) {
        let message = "Echec de la creation du docteur";

        if (Axios.isAxiosError(e)) {
          if ((e as AxiosError).response?.status === 409) {
            message = "Impossible de creer le docteur : duplicat";
          }
        }
        toast(message, {
          autoClose: 10000,
          type: "error"
        });
        reject();
      }
    })
  }

  const submitForm = async () => {
    if (!compiler.checkFormValidity()) return;

    const payload: AddDoctorPayload = compiler.compile([
      "firstName", "lastName", "title", "phone", "email", "addressLine1", "addressLine2", "zipCode", "city", "country", "specialty", "rppsNumber"]);

    await handleDoctorsCreation(payload);
    toast('Docteur créé.');
    navigate(ServiceRepository.getInstance().policySvc.policy.getLandingURL());
  }

  return <div className={'doctor-create'}>
    <table className={"form"}>
    <tbody>
    <tr>
      <td/>
      <td><div className={'section-title'}>Identité</div></td>
      <td/>
    </tr>
    <tr>
      <td><label>Titre</label></td>
      <td>
        <TextInput
          t={t}
          ref={ref => ref && compiler.register(ref)}
          id={"title"}
          defaultValue={"Dr"}
          required={true}
          errorMsg={"Champ obligatoire"}
          placeholder={"Dr"}
          validation={(v) => !!v && v?.length > 0}
        />
      </td>
      <td/>
    </tr>
    <tr>
      <td><label>Prénom</label></td>
      <td>
        <TextInput
          t={t}
          ref={ref => ref && compiler.register(ref)}
          id={"firstName"}
          required={true}
          errorMsg={"Champ obligatoire"}
          defaultValue={"Louis"}
          placeholder={"Louis"}
          validation={(v) => !!v && v?.length > 0}
        />
      </td>
      <td/>
    </tr>
    <tr>
      <td><label>Nom</label></td>
      <td>
        <TextInput
          t={t}
          ref={ref => ref && compiler.register(ref)}
          id={"lastName"}
          required={true}
          errorMsg={"Champ obligatoire"}
          defaultValue={"Pasteur"}
          placeholder={"Pasteur"}
          validation={(v) => !!v && v?.length > 0}
        />
      </td>
      <td/>
    </tr>
    <tr>
      <td/>
      <td><div className={'section-title'}>Coordonnées</div></td>
      <td/>
    </tr>
    <tr>
      <td><label>Email</label></td>
      <td>
        <TextInput
          t={t}
          ref={ref => ref && compiler.register(ref)}
          id={"email"}
          required={true}
          errorMsg={"Champ obligatoire"}
          defaultValue={"fritschharold+pasteur@gmail.com"}
          placeholder={"louis.pasteur@pasteur.fr"}
          validation={(v) => !!v && v?.length > 0}
        />
      </td>
      <td/>
    </tr>
    <tr>
      <td><label>Numéro RPPS</label></td>
      <td>
        <TextInput
          ref={ref => ref && compiler.register(ref)}
          id={"rppsNumber"}
          t={t}
          required={true}
          errorMsg={"Champ obligatoire"}
          defaultValue={"123456"}
          placeholder={"123456"}
          validation={(v) => !!v && v?.length > 0}
        />
      </td>
      <td/>
    </tr>
    <tr>
      <td><label>Spécialité</label></td>
      <td>
        <TextInput
          ref={ref => ref && compiler.register(ref)}
          id={"specialty"}
          t={t}
          required={true}
          errorMsg={"Champ obligatoire"}
          placeholder={"Microbiologiste"}
          defaultValue={"Microbiologiste"}
          validation={(v) => !!v && v?.length > 0}
        />
      </td>
      <td/>
    </tr>
    <tr>
      <td><label>Adresse</label></td>
      <td>
        <TextInput
          ref={ref => ref && compiler.register(ref)}
          id={"addressLine1"}
          t={t}
          required={true}
          errorMsg={"Champ obligatoire"}
          placeholder={"211 rue de Vaugirard"}
          defaultValue={"211 rue de Vaugirard"}
          validation={(v) => !!v && v?.length > 0}
        />
      </td>
      <td/>
    </tr>
    <tr>
      <td><label>Adresse (suite)</label></td>
      <td>
        <TextInput
          ref={ref => ref && compiler.register(ref)}
          id={"addressLine2"}
          t={t}
          required={false}
          errorMsg={""}
          placeholder={"Bâtiment B"}
          defaultValue={"Bâtiment B"}
          validation={(v) => true}
        />
      </td>
      <td/>
    </tr>
    <tr>
      <td><label>Code postal</label></td>
      <td>
        <TextInput
          ref={ref => ref && compiler.register(ref)}
          id={"zipCode"}
          t={t}
          required={true}
          errorMsg={"Champ obligatoire"}
          placeholder={"75015"}
          defaultValue={"75015"}
          validation={(v) => !!v && v?.length > 0}
        />
      </td>
      <td/>
    </tr>
    <tr>
      <td><label>Ville</label></td>
      <td>
        <TextInput
          ref={ref => ref && compiler.register(ref)}
          id={"city"}
          t={t}
          required={true}
          errorMsg={"Champ obligatoire"}
          placeholder={"Paris"}
          defaultValue={"Paris"}
          validation={(v) => !!v && v?.length > 0}
        />
      </td>
      <td/>
    </tr>
    <tr>
      <td><label>Pays</label></td>
      <td>
        <TextInput
          ref={ref => ref && compiler.register(ref)}
          id={"country"}
          t={t}
          required={true}
          errorMsg={"Champ obligatoire"}
          placeholder={"France"}
          defaultValue={"France"}
          validation={(v) => !!v && v?.length > 0}
        />
      </td>
      <td/>
    </tr>
    <tr className={"table-separator"}/>
    <tr>
      <td/>
      <td>
        <Button.Component label={"Créer le docteur"} color={Button.Color.LIGHT_BLUE} onClick={submitForm}/>
      </td>
      <td/>
    </tr>
    </tbody>
  </table>
  </div>;
}

const DoctorCreate: React.FC = () => {


  return  <TabbedContainer
    tab1={{
      title: "Création d'un docteur",
      content: <DoctorCreateForm />
    }}
    tab2={{
      title: "Mon profil",
      icon: "/assets/img/person.svg",
      iconSelected: "/assets/img/person-blue.svg",
      content: <AdminProfile/>
    }}/>;
}

export default DoctorCreate;