import React, {useEffect, useState} from "react";
import './PatientDetails.scss'
import PatientProfileAsDoctor from "../../Pages/PatientProfileAsDoctor";
import {Listener, ServiceRepository} from "../../../services/serviceRepository";
import {useLocation, useNavigate} from "react-router-dom";
import {Policy} from "../../../models/policy/policy";
import TabbedContainer from "../../Header/TabbedContainer";
import PatientProfile from "../../Pages/PatientProfile";
import {Button} from "../../Shared/Button/Button";
import {ReportList} from "../../Pages/ReportList";
import {User} from "../../../models/user";

export const PatientDetailsPage: React.FC = () => {
  const policySvc = ServiceRepository.getInstance().policySvc;
  const userSvc = ServiceRepository.getInstance().userSvc;
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<User.DTO | undefined>(undefined);
  
  useEffect(() => {
      let pathname = location.pathname.split("/");
      if (pathname.length === 0) navigate(-1);

      let uid = pathname[pathname.length - 1];
      userSvc.getUser(uid).then(setUser);
  }, [location.pathname, navigate, userSvc])
  
  useEffect(() => {
    const onPolicyUpdate: Listener<Policy> = {
      onSubjectUpdate(sub?: Policy) {
        navigate(sub?.getLandingURL() || '/', {replace: false});
      }
    };

    policySvc.addListener(onPolicyUpdate);
    return () => policySvc.removeListener(onPolicyUpdate);
  }, [navigate, policySvc]);

  return <TabbedContainer
    tab1={{
      title: "Détail d'un patient",
      content: <PatientDetails user={user}/>
    }}
    tab2={{
      title: "Mon profil",
      iconSelected: "/assets/img/person-blue.svg",
      icon: "/assets/img/person.svg",
      content: <PatientProfile navigate={navigate}/>
    }}/>
}

const PatientDetails: React.FC<{user?: User.DTO}> = (props: {user?: User.DTO}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return <div className={'patient-details-page'}>
    <Button.Component
      label={"← Revenir à la liste des patients"}
      color={Button.Color.CLEAR}
      customClass={"patient-details-page-back"}
      onClick={() => navigate(-1)}
      align={Button.Align.LEFT}
    />
    <div className={"patient-details-page-columns"}>
      <div className={"left-column"}>
        <PatientProfileAsDoctor user={props.user} location={location} navigate={navigate}/>
      </div>
      <div className={"right-column"}>
        {props.user && <ReportList user={props.user} location={location} navigate={navigate}/>}
      </div>
    </div>
  </div>;
}

export default PatientDetails;