import React from "react";
import {Button} from "../Shared/Button/Button";
import "./DoctorProfile.scss";
import {ServiceRepository} from "../../services/serviceRepository";
import {User} from "../../models/user";
import Color = Button.Color;
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {DeleteAccountModal} from "./DeleteAccountModal";

interface DoctorProfileProps {
  navigate: NavigateFunction
  doctor: User.DTO
}

interface DoctorProfileState {
  profile?: User.DoctorProfile
}

export default class DoctorProfileAsAdmin extends React.Component<DoctorProfileProps, DoctorProfileState> {
  private readonly _userSvc = ServiceRepository.getInstance().userSvc;

  constructor(props: DoctorProfileProps, state: DoctorProfileState) {
    super(props, state);

    this.state = {
      //TODO : fetch distant doctor profile instead of using local one
      profile: (this.props.doctor.profile as User.DoctorProfile | undefined)
    };
  }

  private _deleteAccount() {
    ServiceRepository.getInstance().modalSvc.setModal({
      isVisible: true,
      content: <DeleteAccountModal relatedUser={this.props.doctor} />
    })
  }

  render() {
    const user = this.props.doctor;
    const profile = this.state.profile;

    return <div className={"doctor-profile"}>
      <div className={"doctor-profile-header"}>
        <p className={"doctor-full-name"}>{User.prettyName(user)}</p>
        <Button.Component label={"modifier le profil"} customClass={"edit-profile-btn"} color={Color.LINK}
                          onClick={() => this.props.navigate("/doctors/edit/" + this.props.doctor.id)}/>
      </div>
      <table>
        <tbody>
        <tr><td><br/></td></tr>
        <tr>
          <td>Titre :</td>
          <td className={"doctor-profile-value"}>{user?.title || "à définir"}</td>
        </tr>
        <tr>
          <td>Nom de famille :</td>
          <td className={"doctor-profile-value"}>{user?.lastName || "à définir"}</td>
        </tr>
        <tr>
          <td>Prénom(s) :</td>
          <td className={"doctor-profile-value"}>{user?.firstName || "à définir"}</td>
        </tr>
        <tr>
          <td>Numéro RPPS :</td>
          <td className={"doctor-profile-value"}>{profile?.rppsNumber || "à définir"}</td>
        </tr>
        <tr><td><br/></td></tr>
        <tr>
          <td>Téléphone :</td>
          <td className={"doctor-profile-value"}>{profile?.phone || "à définir"}</td>
        </tr>
        <tr>
          <td>Spécialité :</td>
          <td className={"doctor-profile-value"}>{profile?.specialty || "à définir"}</td>
        </tr>
        <tr>
          <td>Adresse :</td>
          <td className={"doctor-profile-value"}>{profile?.addressLine1 || "à définir"}</td>
        </tr>
        <tr>
          <td>Adresse (suite) :</td>
          <td className={"doctor-profile-value"}>{profile?.addressLine2}</td>
        </tr>
        <tr>
          <td>Code postal :</td>
          <td className={"doctor-profile-value"}>{profile?.zipCode || "à définir"}</td>
        </tr>
        <tr>
          <td>Ville :</td>
          <td className={"doctor-profile-value"}>{profile?.city || "à définir"}</td>
        </tr>
        <tr>
          <td>Country :</td>
          <td className={"doctor-profile-value"}>{profile?.country || "à définir"}</td>
        </tr>
        <tr><td><br/></td></tr>
        <tr>
          <td>Adresse mail :</td>
          <td className={"doctor-profile-value"}>{user?.email || "à définir"}</td>
        </tr>
        <tr>
          <td>Supprimer le compte :</td>
          <td><Button.Component label={"SUPPRIMER"} onClick={() => this._deleteAccount()}
                                color={Color.RED}/></td>
        </tr>
        </tbody>
      </table>
      <br/>
    </div>
  }
}