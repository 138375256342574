import React from "react";
import {Button} from "../Shared/Button/Button";
import "./DialogV2.scss"

export function Dialog(props: { rightBtn?: Button.Props, leftBtn?: Button.Props, text?: string, title: string, customContent?: React.ReactNode }) {
  return <div className={"dialog-v2"}>
    <h1>{props.title}</h1>
    {!!props.text && <p>{props.text}</p>}
    {props.customContent ?? props.customContent}
    <div className={"modal-buttons"}>
      {props.leftBtn && <Button.Component {...props.leftBtn} />}
      {props.rightBtn && <Button.Component {...props.rightBtn} />}
    </div>
  </div>;
}