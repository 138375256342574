import {CookieUtils} from "./cookieUtils";
import {URLs} from "./urls";
import {ServiceRepository} from "../services/serviceRepository";
import {Location} from "react-router-dom";
import {NavigateFunction} from "react-router/dist/lib/hooks";

export const allowedUrlsWhileLoggedOut = [
  "",
  "/",
  "/about",
  "/login"
];

export namespace Navigation {
  export enum APP_ROUTES_QUERY_PARAM_ENUM {
    UNAUTHORIZED = "?unauthorized",
    PASSWORD_INIT = "?password_init",
    PASSWORD_RESET = "?password_reset",
    SIGN_OUT = "?signOut",
  }

  export function parseParams(location: Location) : Map<string, any> {
    let searchParams: Map<string, any> = new Map();

    if (location.search && location.search.length > 0 && location.search.charAt(0) === '?') {
      let search = location.search.substring(1);
      let pairs = search.split(',');

      pairs.forEach(pair => {
        let equalIndex = pair.indexOf('=');
        let key = pair.substring(0, equalIndex !== -1 ? equalIndex : pair.length);
        let value = pair.substring(equalIndex !== -1 ? equalIndex + 1 : 0, pair.length);

        searchParams.set(key, value);
      })
    }
    return searchParams;
  }

  export function removeParamFromUrl(location: Location, paramKey: string) {

  }

  export function redirectToLoginPage(navigate: NavigateFunction, param: APP_ROUTES_QUERY_PARAM_ENUM): void {
    console.log("redirectToLoginPage");
    if (window.location.pathname === URLs.Front.LOGIN) return;

    CookieUtils.clearToken();
    navigate(`${URLs.Front.LOGIN}${param}`, {replace: true});
  }

  export function redirectToRoot(navigate: NavigateFunction): void {
    const policySvc = ServiceRepository.getInstance().policySvc;
    console.log("viva la vida");
    if (["", "/", policySvc.policy.getLandingURL()].includes(window.location.pathname)) return;

    console.log(policySvc.policy.getLandingURL());
    navigate(policySvc.policy.getLandingURL(), {replace: true});
  }

  // export function navigate(location: string, trackHistory: boolean) {
  //   if (window.location.pathname === location) {
  //     return;
  //   }
  //
  //   if (trackHistory) {
  //     // customHistory.push(location);
  //   } else {
  //     // customHistory.replace(location === "" ? "/" : location);
  //   }
  // }
}