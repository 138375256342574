import React from "react";
import "./Modal.scss"
import closeImg from "../../assets/images/close.svg";
import {ServiceRepository} from "../../services/serviceRepository";

export interface ModalProps {
  isVisible: Boolean,
  content: JSX.Element | null,
  onClose?: () => void,
  styleClass?: string
}


export class Modal extends React.Component<ModalProps, {}> {
  private _closeModal() {
    this.props.onClose?.();
    ServiceRepository.getInstance().modalSvc.setModal({content: null, isVisible: false, styleClass: ""});
  }

  render() {
    if (!this.props.content) return null;

    return <div className={"modal-container"}>
      <div className={"modal"}>
        {!!this.props.onClose && <img className={"close"} src={closeImg}
             onClick={() => this._closeModal()} alt={"close"}/>}
        {this.props.content}
      </div>
    </div>;

  }
}