import React from "react";
import ListAdapter, {AdapterListener} from "../components/Shared/List/ListAdapter";
import {PatientAPIPagination} from "./PatientAPIPagination";
import {Column} from "../components/Shared/List/Column";
import UsersDataProvider from "./UsersDataProvider";
import {User} from "../models/user";

export interface UserAdapterListener extends AdapterListener<User.DTO> {
  removeClicked: (user: User.DTO) => void;
}

export default class UserAdapter extends ListAdapter<User.DTO, UserAdapterListener, PatientAPIPagination> {
  private _showRemove: boolean | undefined;

  /**
   * constructor
   * @param customersProvider
   * @param listener
   * @param showRemove
   */
  constructor(customersProvider: UsersDataProvider, listener?: UserAdapterListener, showRemove?: boolean) {
    super();

    this.addColumn(new Column('Patient', "lastName", this._name));
    this.addColumn(new Column('Date de naissance', "birthdate", this._birthdate));
    this.addColumn(new Column('Numéro sécu', "socialWelfareNumber", this._socialWelfareNumber));

    this.init(
      "Il n'y a pas encore de patients",
      customersProvider,
      [],
      listener
    );

    this._showRemove = showRemove;

    this.criteria.unshift({
      relatedFilterKey: PatientAPIPagination.GRANT_TYPE_FILTER,
      translationKey: "all",
      value: "all"
    });
  }

  /**
   * _firstName
   * @returns {any}
   * @private
   * @param customer
   */
  private _name = (customer: User.DTO): JSX.Element => {
    return <p className={"blue-hover"}>{customer.lastName + ' ' + customer.firstName}</p>
  };

  /**
   * _socialWelfareNumber
   * @returns {any}
   * @private
   * @param customer
   */
  private _socialWelfareNumber = (customer: User.DTO): JSX.Element => {
    return <p>{(customer.profile as User.PatientProfile | undefined)?.socialWelfareNumber || "N/A"}</p>
  };

  /**
   * _birthdate
   * @returns {any}
   * @private
   * @param customer
   */
  private _birthdate = (customer: User.DTO): JSX.Element => {
    return <p>{(customer.profile as User.PatientProfile | undefined)?.birthdate || "N/A"}</p>
  };
}