import React from "react";
import {Button} from "../Shared/Button/Button";
import "./DoctorProfile.scss";
import {Listener, ServiceRepository} from "../../services/serviceRepository";
import {User} from "../../models/user";
import {ChangePasswordModal} from "./ChangePasswordModal";
import Color = Button.Color;
import {NavigateFunction} from "react-router/dist/lib/hooks";

interface DoctorSelfProfileProps {
  navigate: NavigateFunction
}

interface DoctorSelfProfileState {
  user?: User.DTO,
  profile?: User.DoctorProfile
}

export default class DoctorSelfProfile extends React.Component<DoctorSelfProfileProps, DoctorSelfProfileState> implements Listener<User.DTO>{
  private readonly _userSvc = ServiceRepository.getInstance().userSvc;

  constructor(props: DoctorSelfProfileProps, state: DoctorSelfProfileState) {
    super(props, state);

    this.state = {
      user: this._userSvc.currentUser,
      //TODO : fetch distant doctor profile instead of using local one
      profile: (this._userSvc.currentUser?.profile as User.DoctorProfile | undefined)
    };
  }

  private _resetPassword() {
    ServiceRepository.getInstance().modalSvc.setModal({
      isVisible: true,
      content: <ChangePasswordModal />
    })
  }

  componentDidMount() {
    super.componentDidMount?.();
    this._userSvc.addListener(this);
  }

  componentWillUnmount() {
    super.componentWillUnmount?.();
    this._userSvc.removeListener(this);
  }

  onSubjectUpdate(sub?: User.DTO): void {
    console.log("profile?", sub?.profile)
    const profile : User.DoctorProfile | undefined  = sub?.role === User.Role.DOCTOR ? sub?.profile as User.DoctorProfile : undefined;

    this.setState({
      user: sub,
      profile: profile
    })
  }

  render() {
    const user = this.state.user;
    const profile = this.state.profile;

    return <div className={"doctor-profile"}>
      <div className={"doctor-profile-header"}>
        <p className={"doctor-full-name"}>{User.prettyName(user)}</p>
        <Button.Component label={"modifier le profil"} customClass={"edit-profile-btn"} color={Color.LINK}
                          onClick={() => this.props.navigate("/doctors/edit/" + this.state.user?.id)}/>
      </div>
      <table>
        <tbody>
        <tr><td><br/></td></tr>
        <tr>
          <td>Titre :</td>
          <td className={"doctor-profile-value"}>{user?.title || "à définir"}</td>
        </tr>
        <tr>
          <td>Nom de famille :</td>
          <td className={"doctor-profile-value"}>{user?.lastName || "à définir"}</td>
        </tr>
        <tr>
          <td>Prénom(s) :</td>
          <td className={"doctor-profile-value"}>{user?.firstName || "à définir"}</td>
        </tr>
        <tr>
          <td>Numéro RPPS :</td>
          <td className={"doctor-profile-value"}>{profile?.rppsNumber || "à définir"}</td>
        </tr>
        <tr><td><br/></td></tr>
        <tr>
          <td>Téléphone :</td>
          <td className={"doctor-profile-value"}>{profile?.phone || "à définir"}</td>
        </tr>
        <tr>
          <td>Spécialité :</td>
          <td className={"doctor-profile-value"}>{profile?.specialty || "à définir"}</td>
        </tr>
        <tr>
          <td>Adresse :</td>
          <td className={"doctor-profile-value"}>{profile?.addressLine1 || "à définir"}</td>
        </tr>
        <tr>
          <td>Adresse (suite) :</td>
          <td className={"doctor-profile-value"}>{profile?.addressLine2}</td>
        </tr>
        <tr>
          <td>Code postal :</td>
          <td className={"doctor-profile-value"}>{profile?.zipCode || "à définir"}</td>
        </tr>
        <tr>
          <td>Ville :</td>
          <td className={"doctor-profile-value"}>{profile?.city || "à définir"}</td>
        </tr>
        <tr>
          <td>Country :</td>
          <td className={"doctor-profile-value"}>{profile?.country || "à définir"}</td>
        </tr>
        <tr><td><br/></td></tr>
        <tr>
          <td>Adresse mail :</td>
          <td className={"doctor-profile-value"}>{user?.email || "à définir"}</td>
        </tr>
        <tr>
          <td>Mot de passe :</td>
          <td><Button.Component label={"Réinitialiser son pot de passe"} onClick={() => this._resetPassword()}
                                customClass={"modify-password-btn"} color={Color.LINK}/></td>
        </tr>
        </tbody>
      </table>
      <br/>
      <p>Pour supprimer votre profil, contactez l'administrateur.</p>
    </div>
  }
}