import React from "react";
import ListAdapter, {AdapterListener} from "../components/Shared/List/ListAdapter";
import {PatientAPIPagination} from "./PatientAPIPagination";
import {Column} from "../components/Shared/List/Column";
import ReportsDataProvider from "./ReportsDataProvider";
import {Report} from "../models/report";
import {DateUtils} from "../utils/DateUtils";
import pending from '../assets/images/PENDING.svg';
import staled from '../assets/images/STALED.svg';
import to_review from '../assets/images/TO_REVIEW.svg';
import './ReportAdapter.scss';

export interface ReportAdapterListener extends AdapterListener<Report.DTO> {
}

export default class ReportAdapter extends ListAdapter<Report.DTO, ReportAdapterListener, PatientAPIPagination> {
  private _showRemove: boolean | undefined;

  /**
   * constructor
   * @param reportsDataProvider
   * @param listener
   */
  constructor(reportsDataProvider: ReportsDataProvider, listener?: ReportAdapterListener) {
    super();

    this.addColumn(new Column('Identifiant', "id", this._id));
    this.addColumn(new Column('Date', "createdAt", this._createdAt));
    this.addColumn(new Column('', "", this._showDetail));

    this.init(
      "Il n'y a pas encore de relevés",
      reportsDataProvider,
      [],
      listener
    );

    this._showRemove = false;
  }

  /**
   * _id
   * @returns {any}
   * @private
   * @param report
   */
  private _id = (report: Report.DTO): JSX.Element => {
    return <p className={`${report.status} report-adapter-item blue-hover`}>
      {report.status === Report.Status.PENDING && <img src={pending} alt={"pending"}/>}
      {report.status === Report.Status.STALLED && <img src={staled} alt={"staled"}/>}
      {report.status === Report.Status.TO_REVIEW && <img src={to_review} alt={"to_review"}/>}
     n°{report.id}</p>
  };

  /**
   * _showDetail
   * @returns {any}
   * @private
   * @param report
   */
  private _showDetail = (report: Report.DTO): JSX.Element => {
    return <p className={`${report.status} open-report-details report-adapter-item blue-hover`}>voir le détail du relevé</p>
  };

  /**
   * _createdAt
   * @returns {any}
   * @private
   * @param report
   */
  private _createdAt = (report: Report.DTO): JSX.Element => {
    return <p className={`${report.status} report-adapter-item blue-hover`}>{DateUtils.prettifyTimestamp(report.createdAt, true)}</p>
  };
}