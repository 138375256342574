import {AuthService} from "./authService";

export class FileService {
  static getFileURL(key: string, authSvc: AuthService): string {
    return `${process.env.REACT_APP_API_BASE_URL}/files/${key}?accessToken=${authSvc.accessToken}`;
  }

  static getReportFileURL(reportId: string, key: string, authSvc: AuthService): string {
    return `${process.env.REACT_APP_API_BASE_URL}/reports/${reportId}/files/${key}?accessToken=${authSvc.accessToken}`;
  }

  static getReportPDF(reportId: string, authSvc: AuthService): string {
    return `${process.env.REACT_APP_API_BASE_URL}/reports/${reportId}/pdf?accessToken=${authSvc.accessToken}`;
  }
}