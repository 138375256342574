import React from "react";
import ListAdapter, {AdapterListener} from "../components/Shared/List/ListAdapter";
import {PatientAPIPagination} from "./PatientAPIPagination";
import {Column} from "../components/Shared/List/Column";
import UsersDataProvider from "./UsersDataProvider";
import {User} from "../models/user";

export interface DoctorsAdapterListener extends AdapterListener<User.DTO> {}

export default class DoctorsAdapter extends ListAdapter<User.DTO, DoctorsAdapterListener, PatientAPIPagination> {
  private _showRemove: boolean | undefined;

  /**
   * constructor
   * @param doctorsProvider
   * @param listener
   * @param showRemove
   */
  constructor(doctorsProvider: UsersDataProvider, listener?: DoctorsAdapterListener, showRemove?: boolean) {
    super();

    this.addColumn(new Column('Docteur', "lastName", this._name));
    this.addColumn(new Column('Numéro RPPS', "rpps", this._rpps));

    this.init(
      "Il n'y a pas encore de docteur.",
      doctorsProvider,
      [],
      listener
    );

    this._showRemove = showRemove;

    this.criteria.unshift({
      relatedFilterKey: PatientAPIPagination.GRANT_TYPE_FILTER,
      translationKey: "all",
      value: "all"
    });
  }

  /**
   * _firstName
   * @returns {any}
   * @private
   * @param doctor
   */
  private _name = (doctor: User.DTO): JSX.Element => {
    return <p className={"blue-hover"}>{doctor.lastName + ' ' + doctor.firstName}</p>
  };

  /**
   * _rpps
   * @returns {any}
   * @private
   * @param doctor
   */
  private _rpps = (doctor: User.DTO): JSX.Element => {
    return <p>{(doctor.profile as User.DoctorProfile | undefined)?.rppsNumber || "N/A"}</p>
  };
}