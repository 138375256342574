import {AuthService} from "./authService";
import {UserService} from "./userService";
import {PolicyService} from "./policyService";
import {FileService} from "./fileService";
import {ReportService} from "./reportService";
import {ModalService} from "./modalService";
import {WebcamService} from "./webcamService";

export interface Listener<T> {
  onSubjectUpdate(sub?: T): void;
}

export interface ListenableService<T> {
  addListener(listener: Listener<T>): void;
  removeListener(listener: Listener<T>): void;
}

export class ServiceRepository {
  protected static instance: ServiceRepository;
  private readonly _authSvc: AuthService;
  private readonly _reportSvc: ReportService;
  private readonly _fileSvc: FileService;
  private readonly _policySvc: PolicyService;
  private readonly _userSvc: UserService;
  private readonly _modalSvc: ModalService;
  private readonly _webcamSvc: WebcamService;

  public get authSvc(): AuthService {
    return this._authSvc;
  }

  public get reportSvc(): ReportService {
    return this._reportSvc;
  }

  public get modalSvc(): ModalService {
    return this._modalSvc;
  }

  public get fileSvc(): FileService {
    return this._fileSvc;
  }

  public get policySvc(): PolicyService {
    return this._policySvc;
  }

  public get webcamSvc(): WebcamService {
    return this._webcamSvc;
  }

  public get userSvc(): UserService {
    return this._userSvc;
  }

  public static getInstance(): ServiceRepository {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!ServiceRepository.instance) {
      ServiceRepository.instance = new ServiceRepository();
    }
    return ServiceRepository.instance;
  }

  private constructor() {
    this._authSvc = new AuthService();
    this._userSvc = new UserService();
    this._policySvc = new PolicyService(this._userSvc);
    this._fileSvc = new FileService();
    this._reportSvc = new ReportService();
    this._modalSvc = new ModalService();
    this._webcamSvc = new WebcamService();
  }
}
