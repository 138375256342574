import {Button} from 'components/Shared/Button/Button';
import React from 'react';
import './ReportDetails.scss';
import {Report} from "../../../models/report";
import {DateUtils} from "../../../utils/DateUtils";
import {ServiceRepository} from "../../../services/serviceRepository";
import Color = Button.Color;

export interface ReportDetailsProps {
  report: Report.DTO
  shouldReload: () => {}
}

export const ReportDetails: React.FC<ReportDetailsProps> = (props) => {
  const downloadPDF = () => {
    ServiceRepository.getInstance().reportSvc.downloadPDF(props.report.id);
  }

  return <div className={"report-details"}>
    <table>
      <tbody>
      <tr>
        <th>
          Propriété
        </th>
        <th>
          Valeur
        </th>
      </tr>
      <tr>
        <td>
          Statut
        </td>
        <td>
          {props.report.status}
        </td>
      </tr>
      <tr>
        <td>
          Durée (s)
        </td>
        <td>
          {props.report.sDuration}
        </td>
      </tr>
      <tr>
        <td>
          Identifiant
        </td>
        <td>
          {props.report.id}
        </td>
      </tr>
      <tr>
        <td>
          Nom
        </td>
        <td>
          {props.report.name}
        </td>
      </tr>
      <tr>
        <td>
          Message
        </td>
        <td>
          {props.report.message}
        </td>
      </tr><tr>
        <td>
          Description
        </td>
        <td>
          {JSON.stringify(props.report.description)}
        </td>
      </tr>
      <tr>
        <td>
          Id utilisateur en lien
        </td>
        <td>
          {props.report.relatedUserId}
        </td>
      </tr>
      <tr>
        <td>
          Date de création
        </td>
        <td>
          {DateUtils.prettifyTimestamp(props.report.createdAt, true)}
        </td>
      </tr>
      <tr>
        <td>
          Fichier vidéo
        </td>
        <td>
          {!!props.report.capture?.key ? <Button.Component label={"Télécharger la capture"} onClick={() => {
            ServiceRepository.getInstance().reportSvc.downloadReportFile(
              props.report.id,
              props.report.capture!.key,
              props.report.capture!.key
            );
          }} /> : "Absent"}
        </td>
      </tr>
      <tr>
        <td>
          Actions
        </td>
        <td>
          <Button.Component label={"Supprimer le relevé"} color={Color.RED} onClick={async () => {
            if (window.confirm("Vous allez supprimer le relevé. Cette action est irréversible.")) {
              await ServiceRepository.getInstance().reportSvc.removeReport(props.report.id);
              props.shouldReload();
              ServiceRepository.getInstance().modalSvc.closeCurrentModal();
            }
          }}/>
        </td>
      </tr>
      </tbody>
    </table>
    {[Report.Status.OK, Report.Status.TO_REVIEW].includes(props.report.status) &&
      <Button.Component label={"Télécharger le rapport .pdf"}
                        align={Button.Align.CENTER}
                        color={Button.Color.LIGHT_BLUE}
                        onClick={() => downloadPDF()}
      />
    }
    {![Report.Status.PENDING, Report.Status.STARTED].includes(props.report.status) &&
      <Button.Component label={"(DEV) Remettre le rapport TO_ANALYZE"}
                        align={Button.Align.CENTER}
                        color={Button.Color.RED}
                        onClick={async () => {
                          await ServiceRepository.getInstance().reportSvc.markReportAsToAnalyze(props.report);
                          props.shouldReload();
                          ServiceRepository.getInstance().modalSvc.closeCurrentModal();
                        }}/>
    }
    {Report.Status.TO_REVIEW === props.report.status &&
      <Button.Component label={"Marquer le rapport comme lu"}
                        align={Button.Align.CENTER}
                        color={Button.Color.LIGHT_BLUE}
                        onClick={async () => {
                          await ServiceRepository.getInstance().reportSvc.markReportAsReviewed(props.report);
                          props.shouldReload();
                          ServiceRepository.getInstance().modalSvc.closeCurrentModal();
                        }}/>
    }
  </div>;
};