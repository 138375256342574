import React from "react";
import {Button} from "../Shared/Button/Button";
import "./PatientProfile.scss";
import {Listener, ServiceRepository} from "../../services/serviceRepository";
import {Dialog} from "./Dialog";
import {User} from "../../models/user";
import Color = Button.Color;
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {toast} from "react-toastify";
import {ChangePasswordModal} from "./ChangePasswordModal";

interface PatientProfileProps {
  navigate: NavigateFunction
}

interface PatientProfileState {
  user?: User.DTO,
  profile?: User.PatientProfile,
  doctor?: User.DTO,
  doctorProfile?: User.DoctorProfile
}

export default class PatientProfile extends React.Component<PatientProfileProps, PatientProfileState> implements Listener<User.DTO>{
  private readonly _userSvc = ServiceRepository.getInstance().userSvc;

  constructor(props: PatientProfileProps, state: PatientProfileState) {
    super(props, state);

    this.state = {
      user: this._userSvc.currentUser,
      profile: this._userSvc.currentUser?.profile as User.PatientProfile
    };
  }

  componentDidMount() {
    super.componentDidMount?.();
    this._userSvc.addListener(this);
    this._refreshDoctor();
  }

  componentWillUnmount() {
    this._userSvc.removeListener(this);
    super.componentWillUnmount?.();
  }

  private _refreshDoctor() {
    if (this.state.profile?.referentDoctorId) {
      this._userSvc.getUser(this.state.profile?.referentDoctorId).then(doctor => {
        const doctorProfile: User.DoctorProfile | undefined = doctor?.role === User.Role.DOCTOR ? doctor?.profile as User.DoctorProfile : undefined;
        this.setState({
          doctor: doctor,
          doctorProfile: doctorProfile
        })
      })
    }

  }

  onSubjectUpdate(sub?: User.DTO): void {
    console.log("sub", sub);
    const profile : User.PatientProfile | undefined = sub?.role === User.Role.PATIENT ? sub?.profile as User.PatientProfile : undefined;

    this.setState({
      user: sub,
      profile: profile
    }, () => {
      this._refreshDoctor();
    })
  }

  private _showChangePasswordModal() {
    ServiceRepository.getInstance().modalSvc.setModal({
      isVisible: true,
      content: <ChangePasswordModal />
    })
  }

  render() {
    const user = this.state.user;
    const profile = this.state.profile;
    const doctor = this.state.doctor;
    const doctorProfile = this.state.doctorProfile;

    return <div className={"patient-profile"}>
      <div className={"patient-profile-header"}>
        <p className={"patient-full-name"}>{User.prettyName(user)}</p>
        {/*<p className={"patient-full-name"}>{User.prettyName(user)}</p>*/}
      </div>
      <table>
        <tbody>
        <tr><td><br/></td></tr>
        <tr>
          <td>Titre :</td>
          <td className={"patient-profile-value"}>{user?.title}</td>
        </tr>
        <tr>
          <td>Nom de famille :</td>
          <td className={"patient-profile-value"}>{user?.lastName}</td>
        </tr>
        <tr>
          <td>Prénom(s) :</td>
          <td className={"patient-profile-value"}>{user?.firstName}</td>
        </tr>
        <tr>
          <td>Date de naissance :</td>
          <td className={"patient-profile-value"}>{profile?.birthdate}</td>
        </tr>
        <tr><td><br/></td></tr>
        <tr>
          <td>Numéro de sécurité sociale :</td>
          <td className={"patient-profile-value"}>{profile?.socialWelfareNumber}</td>
        </tr>
        <tr>
          <td>Médecin traitant :</td>
          <td className={"patient-profile-value"}>{User.prettyName(doctor)} (n RPPS : {doctorProfile?.rppsNumber})</td>
        </tr>
        <tr><td><br/></td></tr>
        <tr>
          <td>Adresse mail :</td>
          <td className={"patient-profile-value"}>{user?.email}</td>
        </tr>
        <tr>
          <td>Mot de passe :</td>
          <td className={"patient-profile-value"}>
            <Button.Component label={"Modifier mon pot de passe"} customClass={"modify-password-btn"}
                              onClick={() => this._showChangePasswordModal()} color={Color.LINK}/>
          </td>
        </tr>
        </tbody>
      </table>

      <p>Pour déclarer un changement à votre médecin traitant ou signaler une erreur dans votre profil, cliquez ici :</p>
      <Button.Component label={"DÉCLARER UN CHANGEMENT"} color={Button.Color.LIGHT_BLUE} align={Button.Align.CENTER}
                        customClass={"declare-chgt-btn"} onClick={async () => {
        await ServiceRepository.getInstance().userSvc.requestChanges();
        toast("Votre médecin a été informé de votre demande.");
      }}/>
      <p>Pour supprimer votre profil (attention, cette action est irréversible), cliquez ici :</p>
      <Button.Component color={Button.Color.LINK} label={"supprimer mon profil"} onClick={() => {
        ServiceRepository.getInstance().modalSvc.setModal({
          isVisible: true,
          content: <Dialog
            title={"Supprimer le profil"}
            text={"Vous allez supprimer votre profil.\nCette action est irréversible.\nVoulez-vous continuer ?"}
            leftBtn={{
              label: "Annuler",
              onClick: () => {
                ServiceRepository.getInstance().modalSvc.setModal({isVisible: false, content: null});
              }
            }}
            rightBtn={{
              color: Button.Color.RED,
              label: "Supprimer",
              onClick: async () => {
                ServiceRepository.getInstance().modalSvc.setModal({isVisible: false, content: null});
                await ServiceRepository.getInstance().userSvc.deleteMe();
                await ServiceRepository.getInstance().authSvc.logout(this.props.navigate, false)
                toast("Compte supprimé");
              }
            }}
          />
        })
      }}/>
    </div>
  }
}