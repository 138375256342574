import React from "react";
import './DoctorDashboard.scss'
import TabbedContainer from "../Header/TabbedContainer";

import PatientList from "./PatientList";
import {useNavigate} from "react-router-dom";

interface DoctorDashboardProps {
  title: string
  secondTab: React.ReactNode
  doctorId?: string
}

const DoctorDashboard: React.FC<DoctorDashboardProps> = (props) => {
  const navigate = useNavigate();

  return <TabbedContainer
    tab1={{
      title: props.title,
      content: <PatientList doctorId={props.doctorId} navigate={navigate}/>
    }}
    tab2={{
      title: "Mon profil",
      iconSelected: "/assets/img/person-blue.svg",
      icon: "/assets/img/person.svg",
      content: props.secondTab
    }}/>;
}

export default DoctorDashboard;