export const flattenObject = (obj:Object, parentKey?:string) => {
  let result = {};

  Object.keys(obj).forEach((key) => {
    // @ts-ignore
    const value = obj[key];
    const _key = parentKey ? parentKey + '.' + key : key;
    if (typeof value === 'object') {
      result = { ...result, ...flattenObject(value, _key) };
    } else {
      // @ts-ignore
      result[_key] = value;
    }
    console.log(`parentKey: "${parentKey}", _key: "${_key}"`);
  });

  return result;
};