import React, {useEffect, useState} from "react";
import {Listener, ServiceRepository} from "../../services/serviceRepository";
import {Policy} from "../../models/policy/policy";
import TabbedContainer from "components/Header/TabbedContainer";
import PatientSplashscreen from "./PatientSplashscreen";
import PatientProfile from "./PatientProfile";
import {useNavigate, useLocation} from "react-router-dom";
import {Navigation} from "../../utils/routes";

const PatientDashboard: React.FC = () => {
  const policySvc = ServiceRepository.getInstance().policySvc;
  const navigate = useNavigate();
  const location = useLocation();
  const [showGreetings, setShowGreetings] = useState<boolean>(false);

  useEffect(() => {
    const onPolicyUpdate: Listener<Policy> = {
      onSubjectUpdate(sub?: Policy) {
        navigate(sub?.getLandingURL() || '/', {replace: false});
      }
    };

    policySvc.addListener(onPolicyUpdate);
    return () => policySvc.removeListener(onPolicyUpdate);
  }, [navigate, policySvc]);


  useEffect(() => {
    let params = Navigation.parseParams(location);
    if (params.has("showGreetings")) {
      setShowGreetings(params.get("showGreetings") as string === "true");
      navigate({search: ""})
    }}, [location, navigate]);

  return <TabbedContainer
    tab1={{
      title: "Faire un relevé",
      content: <PatientSplashscreen navigate={navigate} showGreetings={showGreetings}/>
    }}
    tab2={{
      title: "Mon profil",
      iconSelected: "/assets/img/person-blue.svg",
      icon: "/assets/img/person.svg",
      content: <PatientProfile navigate={navigate}/>
    }}/>
};

export default PatientDashboard;