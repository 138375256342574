import React, {useEffect} from 'react';

import './App.scss';
import Main from "./Main/Main";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Listener, ServiceRepository} from "../services/serviceRepository";
import {Modal, ModalProps} from "./Pages/Modal";
import {useLocation, useNavigate} from "react-router-dom";
import {CookieUtils} from "../utils/cookieUtils";
import {allowedUrlsWhileLoggedOut} from "../utils/routes";

interface ModalContainerProps {

}

interface ModalContainerState {
  modalProps?: ModalProps
}

class ModalContainer extends React.Component<ModalContainerProps, ModalContainerState> implements Listener<ModalProps>{
  constructor(props: ModalContainerProps, state: ModalContainerState) {
    super(props, state);

    this.state = {
      modalProps: undefined
    }
  }

  componentDidMount() {
    ServiceRepository.getInstance().modalSvc.addListener(this)
  }

  componentWillUnmount() {
    ServiceRepository.getInstance().modalSvc.removeListener(this)
  }

  render() {
    const modalProps = this.state.modalProps;

    if (modalProps === undefined) {
      return <></>;
    }

    return <Modal {...modalProps}/>;
  }

  onSubjectUpdate(sub?: ModalProps): void {
    this.setState({
      modalProps: sub
    });
  }
}

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authSvc = ServiceRepository.getInstance().authSvc;

  useEffect(() => {
    try {
      CookieUtils.getToken();
      if (!authSvc.isTokenValid()) throw new Error("invalid token");
    } catch (e) {
      if (!allowedUrlsWhileLoggedOut.includes(location.pathname)) {
        navigate("/")
      }
    }
  }, [authSvc, location, navigate]);

  return (
    <div className="app">
      <Main/>
      <ModalContainer />
      <ToastContainer />
    </div>
  );
};

export default App;
