import React from "react";
import "./Statement.scss"
import {WebcamService, WebcamStream} from "../../services/webcamService";
import crappyOverlay from "../../assets/images/overlay.png";
import {ServiceRepository} from "../../services/serviceRepository";
import StatementSurvey from "./StatementSurvey";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {Report} from "../../models/report";
import {ReportController, ReportControllerListener} from "../../services/reportController";
import {Button} from "components/Shared/Button/Button";
import Color = Button.Color;

interface StatementProps {
  webcamStream: WebcamStream
  navigate: NavigateFunction
  report: Report.DTO
}

interface StatementState {
  reportController: ReportController,
  remainingTime: number,
  progress: number
}

export default class Statement extends React.Component<StatementProps, StatementState> implements ReportControllerListener {
  protected videoPlayer: React.RefObject<HTMLVideoElement>;
  private readonly _webcamService: WebcamService = ServiceRepository.getInstance().webcamSvc;
  private _unmounted: boolean = false;

  constructor(props: StatementProps, state: StatementState) {
    super(props, state);

    this.videoPlayer = React.createRef();
    this.state = {
      reportController: new ReportController(this),
      remainingTime: -1,
      progress: 0
      // webcamStream: ServiceRepository.getInstance().webcamSvc._webcamStream.stream
    }
  }

  recordingProgressDidUpdate = (remainingTime: number, progress: number) => {
    this.setState({
      progress: progress,
      remainingTime: remainingTime
    })
  };

  timeIsUp = () => {
    this._showStatementSurveyModal();
  };

  componentDidUpdate(prevProps: Readonly<StatementProps>, prevState: Readonly<StatementState>, snapshot?: any) {
    if (this.videoPlayer.current && this.props.webcamStream.stream !== this.videoPlayer.current.srcObject) {
      this.videoPlayer.current.srcObject = this.props.webcamStream.stream;
    }

    if (prevProps.webcamStream.isRecording !== this.props.webcamStream.isRecording) {
      console.error("prevProps.webcamStream.isRecording updated", prevProps.webcamStream.isRecording)
    }
  }

  componentDidMount() {
    this._unmounted = false;
    if (this.videoPlayer.current && !this.state.reportController.started) {
      this.videoPlayer.current.srcObject = this.props.webcamStream.stream;
    }
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  private _confirmEndingStatementIntention = () => {
    let confirmation = window.confirm("Êtes-vous sûr(e) de vouloir interrompre le relevé ?");
    if (!confirmation) return;

    this._endStatement();
  }

  private _endStatement = () => {
    ServiceRepository.getInstance().webcamSvc.stopRecording();
  };

  private _showStatementSurveyModal() {
    ServiceRepository.getInstance().modalSvc.setModal({
      isVisible: true,
      styleClass: "statement-survey-modal",
      onClose: undefined,
      content: <StatementSurvey controller={this.state.reportController} report={this.props.report} navigate={this.props.navigate} />
    });
  }

  private _getRemainingTime(remainingTime: number): string {
    let min = Math.floor(remainingTime / 60000);
    let s = Math.floor(remainingTime % 60000) / 1000;

    if (remainingTime === -1) return "initialisation"

    return `${min}:${s > 9 ? s : `0${s}`} restantes`;
  }

  private _getModalTitle(remainingTime: number) {
    if (!this._webcamService.webcamStream.isRecording) {
      if (this._webcamService.webcamStream.blobs.length === 0) {
        return "Positionnez votre visage au centre de l'écran";
      } else {
        return "Relevé terminé";
      }
    } else {
      return `⏺    Relevé en cours - ${this._getRemainingTime(remainingTime)}`;
    }
  }

  private _startRecording = () => {
    console.log("calling _startRecording");
    this._webcamService.startRecording();
    this.state.reportController.startReport(this.props.report);
  }

  private _getModalBottomLine = (props: {isRecording: boolean}) => {
    if (props.isRecording) {
      return <><span className={"tips"} style={{color: "white", textAlign: "center"}}>Bougez le moins possible pour assurer une mesure précise.</span><br/></>
    } else {
      return <><span className={"tips"} style={{color: "white", textAlign: "center"}}>
        Lorsque vous êtes prêt(e), appuyez sur "Démarrer". <Button.Component
        label={"Démarrer"}
        color={Color.LIGHT_BLUE}
        onClick={this._startRecording}/></span><br/></>
    }
  }

  render() {
    const {remainingTime} = this.state;

    return <div className={"statement-modal"}>
      <div className={"statement-modal-title"}>
        <span>{this._getModalTitle(remainingTime)}</span>
        {/*<img src={stop} onClick={() => this._confirmEndingStatementIntention()} alt={"stop-statement"} />*/}
      </div>
      <div className={"progress-bar"} style={{width: `${this.state.progress}%`}}/>
      <div className={"video-container"}>
        <img alt={"face-centering-overlay"} src={crappyOverlay} className={"face-centering-overlay"}/>
        <video ref={this.videoPlayer} autoPlay={true} playsInline>
        </video>
      </div>
      <this._getModalBottomLine isRecording={this.props.webcamStream.isRecording} />
      {/*<Button.Component type={Button.Type.PRIMARY} label={"Terminer la mesure"} onClick={() => this._endStatement()}/>*/}
    </div>
  }

  uploadProgressDidUpdate(total: number, uploaded: number): void {
    console.error("statement.tsx uploadprogressdid update");
  }
}