import React from "react";
import "./Button.scss";

export namespace Button {
  export enum Color {
    DARK_BLUE = "",
    LIGHT_BLUE = "light-blue",
    RED = "red",
    CLEAR = "clear",
    LINK = "link"
  }

  export enum Type {
    NORMAL = "normal",
    ICON = "icon"
  }

  export enum Align {
    LEFT = "align-left",
    CENTER = "align-center",
    RIGHT = "align-right"
  }

  export interface Props {
    label: string,
    onClick?: () => void
    type?: Button.Type
    disabled?: boolean
    maxWidth?: string
    fontSize?: string
    align?: Align
    color?: Color
    customClass?: string
  }

  export interface IconProps extends Button.Props {
    iconUrl: string
    buttonType?: Button.Type
  }

  export const Component: React.FC<Button.Props> = (props) => {
    let classes = ["button"];
    if (props.type) classes.push(props.type);
    if (props.align) classes.push(props.align);
    if (props.color) classes.push(props.color);
    if (props.customClass) classes.push(props.customClass);

    return <button onClick={props.onClick} className={classes.join(" ")}>{props.label}</button>
  }
}

