import APIFile from './api/apifile';

export namespace Report {
  export class CreateDTO {
    relatedUserId: string;
    message?: string;

    constructor(relatedUserId: string, message?: string) {
      this.relatedUserId = relatedUserId;
      this.message = message;
    }
  }

  export enum Status {
    PENDING = "PENDING",//Relevé demandé par le docteur et en attente d’être entamé par l’utilisateur.
    STARTED = "STARTED",//L’utilisateur devant exécuter ce relevé a cliqué sur “lancer le relevé”
    STALLED = "STALLED",//Le relevé est en état “STARTED” depuis plus de X heures et n’a pas été complété. L’utilisateur peut relancer un relevé à volonté tant qu’on est en STALED.
    TO_ANALYZE = "TO_ANALYZE",//Le relevé est prêt à être récupéré par l’algo pour analyse (fichier vidéo présent)
    PROCESSING = "PROCESSING",//Le relevé vient d’être récupéré par l’algo.
    ALGO_STALLED = "ALGO_STALLED",//Le relevé est en état “PROCESSING” depuis plus de Y heures. On considère que l’algo n’enverra pas de résultat pour ce relevé. On le remet en TO_ANALYZE.
    TO_REVIEW = "TO_REVIEW",//L’algo a remonté les résultats. On met à disposition du docteur les informations
    OK = "OK"//terminé
  }

  export class EditDTO {
    description?: any;
    status?: Report.Status;

    constructor(description?: string, status?: Report.Status) {
      this.description = description;
      this.status = status;
    }
  }

  export class DTO {
    id: string;
    name: string;
    message?: string;
    relatedUserId: string;
    description: any;
    createdAt: number;
    sDuration: number = 30;
    status: Status;
    capture?: APIFile;

    constructor(id: string, name: string, relatedUserId: string, description: any, createdAt: number,
                sDuration: number, status: Status, capture: APIFile, message?: string) {
      this.id = id;
      this.name = name;
      this.relatedUserId = relatedUserId;
      this.createdAt = createdAt;
      this.description = description;
      this.sDuration = sDuration;
      // this.sDuration = sDuration;
      this.status = status;
      this.capture = capture;
      this.message = message;
    }
  }
}
