import ApiService from './api/apiService';
import {URLs} from '../utils/urls';
import {Report} from '../models/report';
import {generatePath} from 'react-router-dom';
import {logHelper, tLogStyled} from 'utils/Logger';
import {User} from "../models/user";
import {MultipleEntityResponse} from "../models/api/multipleEntityResponse";
import {ReportController} from "./reportController";
import {FileService} from "./fileService";
import {ServiceRepository} from "./serviceRepository";
import Status = Report.Status;
import {DateUtils} from "../utils/DateUtils";


export class ReportService extends ApiService {
  public async getReport(id: string): Promise<Report.DTO> {
    let url = generatePath(URLs.API.REPORT, {
      reportId: id
    });

    return this.getOne(url);
  }

  public async createReport(relatedUserId: string, message?: string): Promise<Report.DTO> {
    let dto: Report.CreateDTO = {
      relatedUserId: relatedUserId,
      message: message
    }

    return this.post(URLs.API.REPORTS, dto);
  }

  public async editReport(reportId: string, description: any | undefined, status?: Report.Status): Promise<Report.DTO> {
    let dto: Report.EditDTO = {};
    if (description) dto.description = description;
    if (status) dto.status = status;

    let url = generatePath(URLs.API.REPORT, {
      reportId: reportId
    });

    return this.patch(url, dto);
  }

  public addFile(controller: ReportController, reportId: string, file: Blob, filename: string) {
    let url = generatePath(URLs.API.REPORT_CAPTURE, {
      reportId: reportId
    });

    let form = new FormData();
    form.append('attachment', file);

    return new Promise<boolean>((resolve, reject) => {
      tLogStyled(`[ReportService.addFile] Uploading file ${filename} on report id ${reportId}`, logHelper.subdued);

      this.post(url, form, controller.progressListener?.onProgress)
        .then(() => {
          tLogStyled(`[ReportService.addFile] Uploaded file ${filename}  on report id ${reportId}`, logHelper.subduedSuccess);
          resolve(true);
        })
        .catch(e => {
          tLogStyled(`[ReportService.addFile] Failed to upload file ${filename}  on report id ${reportId}`, logHelper.subduedFailed);
          reject(e);
        });
    });
  }

  async downloadReportFile(reportId:string, fileKey: string, fileName: string) {
    let url = FileService.getReportFileURL(reportId, fileKey, ServiceRepository.getInstance().authSvc);
    let file = await this.getFile(url);
    this.saveBlob(file, fileName);
  }

  async downloadPDF(reportId:string) {
    let url = FileService.getReportPDF(reportId, ServiceRepository.getInstance().authSvc);
    let file = await this.getFile(url);
    this.saveBlob(file, `${reportId}-${DateUtils.now()}.pdf`);
  }

  saveBlob(blob: Blob, fileName: string) {
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent('click'));
  }

  async getReportsOfUser(userId: string, status?: Report.Status): Promise<MultipleEntityResponse<Report.DTO>> {
    let url = `${generatePath(URLs.API.REPORTS)}?relatedUserId=${userId}`;

    if (status) url = url.concat("&status=", status.toString())

    return await this.getList(url);
  }

  public requestReport(patient: User.DTO, message?: string) {
    this.createReport(patient.id, message);
  }

  public async markReportAsReviewed(report: Report.DTO): Promise<Report.DTO> {
    return await this.editReport(report.id, report.description, Status.OK);
  }

  public async markReportAsToAnalyze(report: Report.DTO): Promise<Report.DTO> {
    return await this.editReport(report.id, report.description, Status.TO_ANALYZE);
  }

  public async getPendingReports(userId: string): Promise<Array<Report.DTO>> {
    const reports = await this.getReportsOfUser(userId, Report.Status.PENDING);
    return reports.data;
  }

  public async removeReport(reportId: string): Promise<void> {
    let url = generatePath(URLs.API.REPORT, {
      reportId: reportId
    });

    return await this.delete(url);
  }
}