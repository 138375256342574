import React from "react";
import bLogo from 'assets/images/img.png';
import fullLogo from 'assets/images/logo_baseline_bleu_transparent.png';
import "./About.scss"
import {useNavigate} from "react-router-dom";

const About: React.FC = () => {
  const navigate = useNavigate();

  return <div className={"about-container"}>
    <img className={"about-back-logo"} onClick={() => navigate(-1)} src={bLogo} alt={"b2b blogo"}/>
    <div className={"about-content"}>
      <img className={"about-center-logo"} src={fullLogo} alt={"b2b logo"}/>
      <h1>... c'est quoi ?</h1>
      <div className="entry-content">


        <p className="justified-text">Bits2Beat Predictive Analytics is a French DeepTech startup company, based in
          Bordeaux, in the digital Health Care sector. </p>


        <p>Its purpose is the design of medical software, intended for doctors and their patients, delivering a
          predictive analysis of the heart rhythm, for the early detection and prevention of the consequences of some of
          the most common <strong><em>asymptomatic</em></strong> cardiovascular diseases. The impact of these
          pathologies on the population is very negative with several million deaths or disabilities each year in France
          (15% of the causes of death).</p>


        <p>The value proposition of Bits2Beat Predictive Analytics is the integration of its software with consumer
          devices (smartphones, webcams, smart wrist bands, etc.), whose coverage is almost universal and the use very
          ergonomic, while integrating transversally to the entire healthcare pathway, from early detection to
          post-diagnosis or post-operative follow-up.</p>


        <p>Bits2Beat has set itself the short-term objective of developing a demonstrator that can be deployed in
          particular in hospital structures and in partner doctor’s offices, but also with voluntary individuals, for
          the acquisition and interpretation (by segmentation and classification) of the heart rhythm via consumer
          digital device cameras by rPPG (for «&nbsp;remote photoplethysmography&nbsp;»). Making the rPPG solution
          accessible on the main platforms on the market is our next goal, as well as enriching it by coupling it to
          other types of data acquisition mainly from IoT (smartwatches, ECG belts for sports training, etc.) to prevent
          the risk of cardiovascular accidents.</p>


        <p>The personalization aiming for predictive monitoring of treatment, which our company is developing with its
          proprietary technology, is a major R&amp;D challenge in this sector of medicine known as 6P (Personalization,
          Prediction, Prevention, care Pathway, Participation, Precision).</p>


        <p>The company is now comprised of its two co-founders, Guillaume Attuel and Édouard Kleinpeter, supported in
          the TechnoWest incubator in Bordeaux. A proof of concept has already been established at Inria, based on the
          academic work and the first software developments of the two co-founders.</p>


        <p><a
          href="https://www.inria.fr/fr/startups-bits2beat-predictive-analytics-sante-medecine-personnalisee">https://www.inria.fr/fr/startups-bits2beat-predictive-analytics-sante-medecine-personnalisee</a>
        </p>

      </div>
    </div>
  </div>;
}
export default About;