import React from "react";
import {Button} from "../Shared/Button/Button";
import "./PatientSplashscreen.scss";
import {Listener, ServiceRepository} from "../../services/serviceRepository";
import Statement from "../Main/Statement";
import {WebcamStream, WebcamStreamStatus} from "../../services/webcamService";
import {User} from "../../models/user";
import infoImg from "../../assets/images/info.svg";
import infoBlackImg from "../../assets/images/info_black.svg";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import PatientProfile = User.PatientProfile;
import {Report} from "../../models/report";
import StartStatement from "../Main/StartStatement";

interface PatientSplashcreenProps {
  navigate: NavigateFunction
  showGreetings: boolean
}

interface PatientSplashcreenState {
  pendingReports: Report.DTO[],
  webcamStream: WebcamStream,
  webcamStreamStatus: WebcamStreamStatus,
  user?: User.DTO,
  doctor?: User.DTO
}

export default class PatientSplashscreen extends React.Component<PatientSplashcreenProps, PatientSplashcreenState> implements Listener<WebcamStream | User.DTO>{
  private readonly _webcamSvc = ServiceRepository.getInstance().webcamSvc;
  private readonly _reportSvc = ServiceRepository.getInstance().reportSvc;
  private readonly _userSvc = ServiceRepository.getInstance().userSvc;
  private _isMounted = false;
  static showStatementModalAlreadyRequested: boolean = false;

  constructor(props: PatientSplashcreenProps, state: PatientSplashcreenState) {
    super(props, state);

    this.state = {
      pendingReports: [],
      webcamStream: this._webcamSvc.webcamStream,
      webcamStreamStatus: WebcamStreamStatus.STOPPED,
      user: this._userSvc.currentUser,
      doctor: undefined
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this._webcamSvc.addListener(this);
    this._userSvc.addListener(this);
    this._refreshUser();
    this._refreshWebcamStatus();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._webcamSvc.stopRecording();
    this._webcamSvc.removeListener(this);
    this._userSvc.removeListener(this);
  }

  onSubjectUpdate(sub?: WebcamStream | User.DTO): void {
    if (sub instanceof User.DTO) {
      this.setState({
        user: sub
      }, () => {
        this._refreshHasPendingReport();
        this._refreshDoctor();
      })
    } else {
      //todo : ici il ne faut pas relancer la popup quand on stop le releve
      if (!this._isMounted) return;

      if (!sub) return;

      if (sub?.stream) this._showStatementModal(sub);

      this.setState({
        webcamStream: sub
      }, () => {
        this._refreshWebcamStatus();
      })
    }
  }

  private _refreshUser() {
    this._userSvc.getCurrentUser().then(user => {
      this.setState({
        user: user
      }, () => {
        this._refreshDoctor();
        this._refreshHasPendingReport();
      });
    });
  }

  private _refreshDoctor() {
    const profile = this.state.user?.profile as PatientProfile;
    if (!profile) return;

    this._userSvc.getUser(profile.referentDoctorId).then(doctor => {
      this.setState({
        doctor:doctor
      })
    })
  }

  private _refreshHasPendingReport() {
    const currentUserId = this.state.user?.id;
    if (!currentUserId) return;

    this._reportSvc.getPendingReports(currentUserId).then(pendingReports => {
      this.setState({
        pendingReports: pendingReports
      })
    });
  }

  private _refreshWebcamStatus() {
    let webcamStatus: WebcamStreamStatus = WebcamStreamStatus.STOPPED;
    if (this.state.webcamStream.error === "NotAllowedError") {
      webcamStatus = WebcamStreamStatus.REFUSED;
    }
    if (this.state.webcamStream.stream != null) {
      console.log("this.state.webcamStream.stream");
      console.log(this.state.webcamStream.stream);
      webcamStatus = WebcamStreamStatus.STARTED;
    }

    this.setState({
      webcamStreamStatus: webcamStatus
    })
  }

  private async _showStartScreenModal() {
    PatientSplashscreen.showStatementModalAlreadyRequested = false;
    ServiceRepository.getInstance().modalSvc.setModal({
      isVisible: true,
      styleClass: "statement-modal",
      content: <StartStatement goToStatement={() => {
        ServiceRepository.getInstance().modalSvc.closeCurrentModal();
        ServiceRepository.getInstance().webcamSvc.startWebcamDisplay({
          height: 1080,
          width: 1920
        });}
      }/>
    });
  }

  private async _showStatementModal(webcamStream: WebcamStream) {
    //TODO : start statement on backend side.
    const reportSvc = ServiceRepository.getInstance().reportSvc;
    const currentUserId = this._userSvc.currentUser?.id.toString();
    if (!currentUserId) return;

    let report: Report.DTO;
    if (PatientSplashscreen.showStatementModalAlreadyRequested) return;
    PatientSplashscreen.showStatementModalAlreadyRequested = true;

    if (this.state.pendingReports.length === 0) {
      console.log("Now creating report.")
      report = await reportSvc.createReport(currentUserId);
    } else {
      console.log("Now editing report.")
      report = this.state.pendingReports[0];
      await reportSvc.editReport(report.id, undefined, Report.Status.STARTED);
    }

    ServiceRepository.getInstance().modalSvc.setModal({
      isVisible: true,
      styleClass: "statement-modal",
      // onClose: () => {
      //   console.error("on close callback");
      //   this._webcamService.stopRecording();
      //
      // },
      content: <Statement report={report} navigate={this.props.navigate} webcamStream={webcamStream}/>
    });
  }

  render() {
    //TODO : replace this by an API check
    const {pendingReports, doctor, user} = this.state;

    // console.error("pendingReports", pendingReports)

    return <div className={"patient-splashcreen"}>
      {pendingReports.length > 0 && <p className={"welcome-message"}>
        Bonjour {user && User.prettyName(user)} !<br/>
        Vous avez une demande de relevé en attente de la part de votre cardiologue, {User.prettyName(doctor)}.<br/>
        {pendingReports[0].message !== undefined && <>Votre médecin a souhaité vous communiquer le message suivant :<br/>
          <div className={"doctor-message"}>{pendingReports[0].message}</div>
        </>}
        Pour effectuer le relevé, veuillez cliquer sur le bouton "LANCER UN RELEVÉ" ci-dessous et suivre les
        instructions.
      </p>}
      {pendingReports.length === 0 && <p className={"welcome-message"}>
        Bonjour {user && User.prettyName(user)} !<br/>
        Vous n'avez pas de relevé en attente.<br/>
        Cependant, si vous souhaitez tout de même effectuer un relevé, veuillez cliquer sur le bouton "LANCER UN RELEVÉ" ci-dessous et suivre les
        instructions.
      </p>}
      {this.props.showGreetings && <p className={"greetings-message"}>
        <img style={{height: "25px", width: "25px", objectFit: "contain"}} src={infoImg} alt={"information"}/>
        <br/>
        Nous avons bien enregistré la capture ainsi que vos réponses au questionnaire de fin de relevé.<br/>
        <span>Vous pouvez dès maintenant quitter cette page.</span><br />
        Merci d'avoir utilisé Bits2Beat<br />
        À bientôt
      </p>}
      {!this.props.showGreetings && <p className={"permission-message"}>
        <img style={{height: "25px", width: "25px", objectFit: "contain"}} src={infoBlackImg} alt={"information"}/>
        <br/>
        Votre navigateur internet va vous demander une autorisation d'accès à votre caméra.<br/>
        Merci de l'accepter afin de pouvoir effectuer votre relevé.<br/>
        <span>En aucun cas votre caméra ne sera utilisée en dehors de la durée du relevé.</span>
      </p>}
      {
        this.state.webcamStreamStatus === WebcamStreamStatus.STOPPED && <Button.Component label={"LANCER UN RELEVÉ"} customClass={"trigger-capture-btn"} align={Button.Align.CENTER} onClick={() => {
          this._showStartScreenModal();
        }}/>
      }
      {this.state.webcamStreamStatus === WebcamStreamStatus.STARTED && <Button.Component label={"EN COURS"} disabled={true} onClick={() => {}}/>}
      {this.state.webcamStreamStatus === WebcamStreamStatus.REFUSED && <Button.Component label={"PERMISSION REFUSÉE"} disabled={false} onClick={() => {alert("affichage aide ?")}}/>}
      {this.state.webcamStreamStatus === WebcamStreamStatus.PENDING_APPROVAL && <Button.Component label={"EN ATTENTE D'AUTORISATION"} disabled={true} onClick={() => {}}/>}
    </div>
  }
}