import {AccessTokenDTO} from "../models/accessTokenDTO";
import {CookieUtils} from "../utils/cookieUtils";
import {SingleEntityResponse} from "../models/api/singleEntityResponse";
import ApiService from "./api/apiService";
import {URLs} from "../utils/urls";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {ServiceRepository} from "./serviceRepository";
import {Dialog} from "../components/Pages/Dialog";
import {Button} from "../components/Shared/Button/Button";
import React from "react";

export class AuthService extends ApiService {
  private _accessToken : string | undefined = undefined;

  constructor() {
    super();

    try {
      this._accessToken = CookieUtils.getToken().accessToken;
    } catch (e) {
      //nothing to do on catch. Still need to initialize service.
    }
  }


  public get accessToken() : string | undefined {
    return this._accessToken;
  }

  public login = async (username: string, password: string): Promise<void> => {
    const response: AccessTokenDTO = await this.post<AccessTokenDTO>(
      URLs.API.LOGIN,
      {
        email: username,
        password: password,
      }
    );

    console.log("got response : ", response);

    this._accessToken = response.accessToken;
    CookieUtils.saveToken(response);
    return;
  };

  public requestPasswordReset = async (email: string): Promise<void> => await this.post<void>(URLs.API.RESET_PASSWORD, {login: email});

  public resetPassword = async (token: string, password: string): Promise<void> => await this.patch<void>(`${URLs.API.RESET_PASSWORD}?token=${token}`, {
    newPassword: password,
  });

  public logout = async (navigate: NavigateFunction, confirm: boolean = true): Promise<void> => {
    if (confirm) {
      ServiceRepository.getInstance().modalSvc.setModal({
        isVisible: true,
        content: <Dialog
          title={"Déconnexion"}
          text={"Vous allez être déconnecté(e),\nvoulez-vous continuer ?"}
          leftBtn={{
            color: Button.Color.DARK_BLUE,
            label: "Annuler",
            onClick: () => {
              ServiceRepository.getInstance().modalSvc.setModal({isVisible: false, content: null});
            }
          }}
          rightBtn={{
            color: Button.Color.RED,
            label: "Déconnecter",
            onClick: () => {
              ServiceRepository.getInstance().modalSvc.setModal({isVisible: false, content: null});
              this.httpClient.post<void,
                SingleEntityResponse<void>>(URLs.API.LOGOUT).then(() => {
                CookieUtils.clearToken();
                navigate("/", {replace: false})
              });
            }
          }}
        />
      })
    } else {
      this.httpClient.post<void,
        SingleEntityResponse<void>>(URLs.API.LOGOUT).then(() => {
        CookieUtils.clearToken();
        navigate("/", {replace: false})
      });
    }
  };

  public isTokenValid(): boolean {
    return true;
  }
}