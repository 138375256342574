import React, {useEffect, useState} from 'react';
import closeImg from 'assets/images/close-dark.svg';
import eye from 'assets/images/eye.svg';
import {Listener, ServiceRepository} from 'services/serviceRepository';
import {Policy} from 'models/policy/policy';
import {Navigation} from 'utils/routes';
import "./Login.scss";
import {Button} from "../Shared/Button/Button";
import {useNavigate} from "react-router-dom";

const Login: React.FC = () => {
  const [username, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const authSvc = ServiceRepository.getInstance().authSvc;
  const userSvc = ServiceRepository.getInstance().userSvc;
  const policySvc = ServiceRepository.getInstance().policySvc;
  const navigate = useNavigate();

  // useEffect(() => CookieUtils.clearToken(), []);

  useEffect(() => {
    const onPolicyUpdate: Listener<Policy> = {
      onSubjectUpdate(sub?: Policy) {
        if (!sub) return;
        navigate(sub?.getLandingURL() || '/', {replace: false});
      }
    };

    policySvc.addListener(onPolicyUpdate);
    return () => policySvc.removeListener(onPolicyUpdate);
  }, [navigate, policySvc]);

  useEffect(() => {
    if (username.length > 0 && password.length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [username, password]);

  const handleSubmit = async () => {
    if (!isFormValid) return;

    setIsFormValid(false);
    setError(false);

    try {
      await authSvc.login(username, password);
      await userSvc.refreshCurrentUserProfile();
      // Navigation.navigate("/patient", false);
    } catch (e) {
      console.error(e);
      return setError(true);
    }
  };

  const resetUserPassword = async () => {
    if (username.length <= 0) {
      alert("Afin de réinitialiser votre mot de passe, veuillez saisir votre email dans le champ dédié.")
      return;
    }

    alert("Un mail a été envoyé à l'adresse ci-dessus. Suivez les instructions pour définir un nouveau mot de passe.")
    console.log(username);
    await authSvc.requestPasswordReset(username);
  }

  return <div className={"login-padding-box"}>
    <div className={"box"}>
      <img className={"close"} src={closeImg} onClick={() => Navigation.redirectToRoot(navigate)} alt={"close"}/>
      <p className={"title"}>Connexion</p>
      <input className={"username-input"} placeholder="Saisissez votre adresse mail" type="text" autoComplete="username" onChange={e => setUserName(e.target.value)}/>
      <div className={"password-container"}>
        <input placeholder="Saisissez votre mot de passe" type={showPassword ? "text" : "password"} autoComplete="current-password" onChange={e => setPassword(e.target.value)}/>
        <img className={"password-eye"} onClick={() => setShowPassword(!showPassword)} alt={"show-password"} src={eye}/>
      </div>

      {error && <p className={'label-error'}>Les identifiants renseignés ne nous<br/> permettent pas de vous authentifier.</p>}

      <p className={'login-forgot-password'} onClick={() => resetUserPassword()}>
        J'ai oublié mon mot de passe
      </p>

      <Button.Component
        onClick={handleSubmit}
        disabled={!isFormValid}
        color={Button.Color.LIGHT_BLUE}
        label={"CONNEXION"}/>
    </div>
  </div>
};

export default Login;