import React from "react";
import './B2BAdminDashboard.scss'
import TabbedContainer from "../Header/TabbedContainer";
import DoctorList from "./DoctorList";
import AdminProfile from "./AdminProfile";
import {useNavigate} from "react-router-dom";

const B2BAdminDashboard: React.FC = () => {
  const navigate = useNavigate();

  return <TabbedContainer
    tab1={{
      title: "Mes docteurs",
      content: <DoctorList navigate={navigate}/>
    }}
    tab2={{
      title: "Mon profil",
      icon: "/assets/img/person.svg",
      iconSelected: "/assets/img/person-blue.svg",
      content: <AdminProfile/>
    }}/>;
}

export default B2BAdminDashboard;