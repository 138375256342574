import React, {useEffect, useState} from "react";
import {ServiceRepository} from "services/serviceRepository";
import {User} from "models/user";
import {toast} from "react-toastify";
import Axios, {AxiosError} from "axios";
import {FormCompiler} from "../../../utils/Form/FormCompiler";
import {AddPatientPayload} from "../../../services/userService";
import TextInput from "../../Shared/TextInput";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import bLogo from "../../../assets/images/img.png";
import "./PatientEdit.scss";
import {Button} from "components/Shared/Button/Button";

const PatientEdit: React.FC = () => {
  const userService = ServiceRepository.getInstance().userSvc;
  const navigate = useNavigate();
  const {t} = useTranslation();
  const compiler = new FormCompiler();
  const [user, setUser] = useState<User.DTO | undefined>();
  const {userId} = useParams();

  useEffect(() => {
    if (!userId) return navigate('/patients', {replace: true});
    userService.getUser(userId).then((user => {
      setUser(user)
    }));
  }, [navigate, userId, userService])

  const handlePatientEdition = async (payload: AddPatientPayload): Promise<User.DTO> => {
    return new Promise(async (resolve, reject) => {
      if (!user) return reject();

      try {
        resolve(await userService.editUser(user.id, payload));
      } catch (e) {
        let message = t('editPatientFailed');

        if (Axios.isAxiosError(e)) {
          if ((e as AxiosError).response?.status === 409) {
            message = t('editPatientFailedDuplicate')
          }
        }
        toast(message, {
          autoClose: 10000,
          type: "error"
        });
        reject();
      }
    })
  }

  const submitForm = async () => {
    if (!compiler.checkFormValidity()) return;
    const payload: AddPatientPayload = compiler.compile([
      "firstName", "lastName", "email", "phone", "title", "preferredLanguage", "socialWelfareNumber", "birthdate",
      "referentDoctorId"]);

    payload.profile = {
      socialWelfareNumber: payload.profile?.socialWelfareNumber!!,
      referentDoctorId: payload.profile?.referentDoctorId!!,
      birthdate: payload.profile?.birthdate!!,
      phone: payload.profile?.phone!!,
    }

    await handlePatientEdition(payload);
    toast(t('editPatientConfirmation'));
    navigate(-1);
  }

  return  <div className={"patient-edit"}>
    <div className={"header"}>
      <div className={"back-logo"}>
        <img onClick={() => navigate(-1)} src={bLogo} alt={"b2b blogo"}/>
      </div>
      <div className={`header-tab center selected`}>
        Édition d'un patient
      </div>
    </div>
    <table className={"form"}>
      <tbody>
      <tr>
        <td/>
        <td>
          <div className={'section-title'}>Identité</div>
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Titre</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"title"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"Dr"}
            defaultValue={user?.title}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Prénom</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"firstName"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"Louis"}
            defaultValue={user?.firstName}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Nom de famille</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"lastName"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"Pasteur"}
            defaultValue={user?.lastName}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Numéro de sécurité sociale</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"socialWelfareNumber"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"01234"}
            defaultValue={(user?.profile as User.PatientProfile | undefined)?.socialWelfareNumber}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td/>
        <td>
          <div className={'section-title'}>Coordonnées</div>
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Adresse email</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"email"}
            required={true}
            errorMsg={"invalidMail"}
            placeholder={"louis.pasteur@gmail.com"}
            defaultValue={user?.email}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Numéro de téléphone</label></td>
        <td>
          <TextInput
            ref={ref => ref && compiler.register(ref)}
            id={"phone"}
            t={t}
            required={true}
            errorMsg={"Champ obligatoire"}
            placeholder={"0601020304"}
            defaultValue={user?.profile ? (user?.profile as User.PatientProfile).phone : undefined}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr className={"table-separator"}/>
      <tr>
        <td/>
        <td>
          <Button.Component label={"Éditer l'utilisateur"} color={Button.Color.LIGHT_BLUE} onClick={submitForm}/>
        </td>
        <td/>
      </tr>
      </tbody>
    </table>
  </div>
}

export default PatientEdit;