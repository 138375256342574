import React, {useEffect, useState} from "react";
import {ServiceRepository} from "services/serviceRepository";
import {User} from "models/user";
import {toast} from "react-toastify";
import Axios, {AxiosError} from "axios";
import {FormCompiler} from "../../../utils/Form/FormCompiler";
import {AddDoctorPayload} from "../../../services/userService";
import TextInput from "../../Shared/TextInput";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './DoctorEdit.scss';
import bLogo from "../../../assets/images/img.png";
import {Button} from "../../Shared/Button/Button";

const DoctorEdit: React.FC = () => {
  const userService = ServiceRepository.getInstance().userSvc;
  const navigate = useNavigate();
  const {t} = useTranslation();
  const compiler = new FormCompiler();
  const [user, setUser] = useState<User.DTO | undefined>();
  const {userId} = useParams();

  useEffect(() => {
    if (!userId) return navigate('/doctors', {replace: true});
    userService.getUser(userId).then((user => {
      setUser(user)
    }));
  }, [navigate, userId, userService])

  const handleDoctorEdition = async (payload: Partial<AddDoctorPayload>): Promise<User.DTO> => {
    return new Promise(async (resolve, reject) => {
      if (!user) return reject();

      try {
        resolve(await userService.editUser(user.id, payload));
      } catch (e) {
        let message = t('editDoctorFailed');

        if (Axios.isAxiosError(e)) {
          if ((e as AxiosError).response?.status === 409) {
            message = t('editDoctorFailedDuplicate')
          }
        }
        toast(message, {
          autoClose: 10000,
          type: "error"
        });
        reject();
      }
    })
  }

  const submitForm = async () => {
    if (!compiler.checkFormValidity()) return;
    const compiledForm: any = compiler.compile([
      "firstName", "lastName", "phone", "title", "preferredLanguage", "rppsNumber", "specialty",
      "zipCode", "country", "addressLine1", "addressLine2", "city"]);

    let payload: Partial<AddDoctorPayload> = {
      // email: compiledForm.email,
      firstName: compiledForm.firstName,
      lastName: compiledForm.lastName,
      title: compiledForm.title,
      profile: {
        phone: compiledForm.phone,
        rppsNumber: compiledForm.rppsNumber,
        city: compiledForm.city,
        country: compiledForm.country,
        zipCode: compiledForm.zipCode,
        addressLine1: compiledForm.addressLine1,
        addressLine2: compiledForm.addressLine2,
        specialty: compiledForm.specialty,
      }
    }

    await handleDoctorEdition(payload);
    toast(t('Modifications sauvegardées.'));
    userService.refreshCurrentUserProfile().then(() => navigate(-1));
  }

  return  <div className={"doctor-edit"}>
    <div className={"header"}>
      <div className={"back-logo"}>
        <img onClick={() => navigate(-1)} src={bLogo} alt={"b2b blogo"}/>
      </div>
      <div className={`header-tab center selected`}>
        Édition d'un docteur
      </div>
    </div>
    <table className={"form"}>
      <tbody>
      <tr>
        <td/>
        <td>
          <div className={'section-title'}>Identité</div>
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Titre</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"title"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"Dr"}
            defaultValue={user?.title}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Prénom</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"firstName"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"Louis"}
            defaultValue={user?.firstName}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Nom de famille</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"lastName"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"Pasteur"}
            defaultValue={user?.lastName}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Numéro RPPS</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"rppsNumber"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"01234"}
            defaultValue={(user?.profile as User.DoctorProfile | undefined)?.rppsNumber}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Spécialité</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"specialty"}
            errorMsg={"Champ obligatoire"}
            required={true}
            placeholder={"Cardiologie"}
            defaultValue={(user?.profile as User.DoctorProfile | undefined)?.specialty}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td/>
        <td>
          <div className={'section-title'}>Coordonnées</div>
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Adresse email</label></td>
        <td>
          <TextInput
            t={t}
            ref={ref => ref && compiler.register(ref)}
            id={"email"}
            required={false}
            readonly={true}
            errorMsg={"invalidMail"}
            placeholder={"louis.pasteur@gmail.com"}
            defaultValue={user?.email}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Numéro de téléphone</label></td>
        <td>
          <TextInput
            ref={ref => ref && compiler.register(ref)}
            id={"phone"}
            t={t}
            required={true}
            errorMsg={"Champ obligatoire"}
            placeholder={"0601020304"}
            defaultValue={user?.profile ? (user?.profile as User.DoctorProfile).phone : undefined}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Adresse ligne 1</label></td>
        <td>
          <TextInput
            ref={ref => ref && compiler.register(ref)}
            id={"addressLine1"}
            t={t}
            required={true}
            errorMsg={"Champ obligatoire"}
            placeholder={"1 cours Georges Clémenceau"}
            defaultValue={user?.profile ? (user?.profile as User.DoctorProfile).addressLine1 : undefined}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Adresse ligne 2 (facultatif)</label></td>
        <td>
          <TextInput
            ref={ref => ref && compiler.register(ref)}
            id={"addressLine2"}
            t={t}
            required={false}
            errorMsg={"Champ obligatoire"}
            placeholder={"Batiment B"}
            defaultValue={user?.profile ? (user?.profile as User.DoctorProfile).addressLine2 : undefined}
            validation={(v) => true}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Code postal</label></td>
        <td>
          <TextInput
            ref={ref => ref && compiler.register(ref)}
            id={"zipCode"}
            t={t}
            required={true}
            errorMsg={"Champ obligatoire"}
            placeholder={"33000"}
            defaultValue={user?.profile ? (user?.profile as User.DoctorProfile).zipCode : undefined}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Ville</label></td>
        <td>
          <TextInput
            ref={ref => ref && compiler.register(ref)}
            id={"city"}
            t={t}
            required={true}
            errorMsg={"Champ obligatoire"}
            placeholder={"Bordeaux"}
            defaultValue={user?.profile ? (user?.profile as User.DoctorProfile).city : undefined}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr>
        <td><label>Pays</label></td>
        <td>
          <TextInput
            ref={ref => ref && compiler.register(ref)}
            id={"country"}
            t={t}
            required={true}
            errorMsg={"Champ obligatoire"}
            placeholder={"France"}
            defaultValue={user?.profile ? (user?.profile as User.DoctorProfile).country : undefined}
            validation={(v) => !!v && v?.length > 0}
          />
        </td>
        <td/>
      </tr>
      <tr className={"table-separator"}/>
      <tr>
        <td/>
        <td>
          <Button.Component label={"Éditer l'utilisateur"} color={Button.Color.LIGHT_BLUE} onClick={submitForm}/>
        </td>
        <td/>
      </tr>
      </tbody>
    </table>
  </div>
}

export default DoctorEdit;