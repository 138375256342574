import React, {useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';

import './Main.scss';
import {URLs} from "../../utils/urls";
import Login from "./Login";
import B2BAdminDashboard from "../Pages/B2BAdminDashboard";
import {ServiceRepository} from "../../services/serviceRepository";
import {User} from "../../models/user";
import UsersList from "./User/UsersList";
import PatientCreate from "./User/PatientCreate";
import DoctorCreate from "./User/DoctorCreate";
import DoctorEdit from "./User/DoctorEdit";
import DoctorDetails from "./User/DoctorDetails";
import {PatientDetailsPage} from "./User/PatientDetails";
import PatientEdit from "./User/PatientEdit";
import Profile from "./User/Profile";
import Root from "./Root";
import About from "./About";
import PatientDashboard from "../Pages/PatientDashboard";
import DoctorDashboard from 'components/Pages/DoctorDashboard';
import DoctorSelfProfile from "../Pages/DoctorSelfProfile";

const Main: React.FC = () => {
  const usrSvc = ServiceRepository.getInstance().userSvc;
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(usrSvc.currentUser);
  // let query = useQuery();
  usrSvc.addListener({
    onSubjectUpdate(sub?: User.DTO) {
      setCurrentUser(sub);
    }
  })

  return (
    <div className={'main'}>
      <Routes>
        <Route path={'/'} element={<Root/>}/>
        <Route path={'about'} element={<About/>}/>
        <Route path={'patient'} element={<PatientDashboard/>}/>
        <Route path={'admin'} element={<B2BAdminDashboard/>}/>
        <Route path={'doctor'} element={<DoctorDashboard doctorId={currentUser?.id} title={'Mes patients'}
                                                         secondTab={<DoctorSelfProfile navigate={navigate} />}/>}/>
        <Route path={'lost'} element={<p>Lost</p>}/>
        <Route path={URLs.Front.LOGIN} element={<Login/>}/>
        <Route path="patients" element={<UsersList role={User.Role.PATIENT}
                                                    referentUserId={currentUser?.role === User.Role.DOCTOR ? currentUser?.id : undefined}/>}/>
        <Route path="patients/:userId" element={<PatientDetailsPage/>}/>
        <Route path="patients/edit/:userId" element={<PatientEdit/>}/>
        <Route path="patients/create" element={<PatientCreate />}/>

        <Route path="doctors" element={<UsersList role={User.Role.DOCTOR}/>}/>
        <Route path="doctors/:doctorId" element={<DoctorDetails />}/>
        <Route path="doctors/create" element={<DoctorCreate/>}/>
        <Route path="doctors/edit/:userId" element={<DoctorEdit/>}/>

        <Route path="profile" element={<Profile/>}/>
      </Routes>
    </div>
  );
};

export default Main;
