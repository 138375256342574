import React from "react";
import "./AdminProfile.scss";
import {Listener, ServiceRepository} from "../../services/serviceRepository";
import {User} from "../../models/user";
import {Button} from "../Shared/Button/Button";
import {ChangePasswordModal} from "./ChangePasswordModal";

interface AdminProfileState {
  user?: User.DTO,
  profile?: User.Profile
}

export default class AdminProfile extends React.Component<{}, AdminProfileState> implements Listener<User.DTO>{
  private readonly _userSvc = ServiceRepository.getInstance().userSvc;

  constructor(props: {}, state: AdminProfileState) {
    super(props, state);

    this.state = {
      user: this._userSvc.currentUser
    };
  }

  private _showChangePasswordModal() {
    ServiceRepository.getInstance().modalSvc.setModal({
      isVisible: true,
      content: <ChangePasswordModal />
    })
  }

  componentDidMount() {
    super.componentDidMount?.();
    this._userSvc.addListener(this);
  }

  componentWillUnmount() {
    super.componentWillUnmount?.();
    this._userSvc.removeListener(this);
  }

  onSubjectUpdate(sub?: User.DTO): void {
    const profile : User.Profile | undefined  = sub?.role === User.Role.ADMIN ? sub?.profile as User.Profile : undefined;

    this.setState({
      user: sub,
      profile: profile
    })
  }

  render() {
    const user = this.state.user;
    const profile = this.state.profile;

    return <div className={"admin-profile"}>
      <div className={"admin-profile-header"}>
        <p className={"admin-full-name"}>{User.prettyName(user)}</p>
      </div>
      <table>
        <tr>
          <td>Titre :</td>
          <td>{user?.title}</td>
        </tr>
        <tr>
          <td>Nom de famille :</td>
          <td>{user?.lastName}</td>
        </tr>
        <tr>
          <td>Prénom(s) :</td>
          <td>{user?.firstName}</td>
        </tr>
        <tr>
          <td>Adresse mail :</td>
          <td>{user?.email}</td>
        </tr>
        <tr>
          <td>Téléphone :</td>
          <td>{profile?.phone ?? "Non défini"}</td>
        </tr>
        <tr>
          <td>Mot de passe :</td>
          <td><Button.Component label={"Modifier mon mot de passe"} onClick={() => this._showChangePasswordModal()}/></td>
        </tr>
      </table>
    </div>
  }
}