import React from "react";
import {useNavigate} from "react-router-dom";
import {ServiceRepository} from "../../services/serviceRepository";

interface MenuProps {
  isMenuOpened: boolean,
  shouldCloseMenu: () => void
}


interface MenuItemProps {
  action: () => void
  label: string
}

class MenuItem extends React.Component<MenuItemProps> {
  render() {
    return <div className={"menu-item"} onClick={this.props.action}>{this.props.label}</div>;
  }
}

export const AdminMenu: React.FC<MenuProps> = (props: MenuProps) => {
  const navigate = useNavigate();
  const policySvc = ServiceRepository.getInstance().policySvc;
  const authSvc = ServiceRepository.getInstance().authSvc;

  return  <div className={'menu-container'} style={{visibility: props.isMenuOpened ? "visible" : "hidden"}} onClick={props.shouldCloseMenu}>
    <div className={"menu"}>
      <MenuItem label={"FERMER LE MENU"} action={props.shouldCloseMenu} />
      <MenuItem label={"MES DOCTEURS"} action={() =>
        navigate(policySvc.policy.getLandingURL() + "?showProfile=false", {replace: true})
      } />
      <MenuItem label={"MON PROFIL"} action={() => {
        navigate(policySvc.policy.getLandingURL() + "?showProfile=true", {replace: true})
      }} />
      <MenuItem label={"À PROPOS"} action={() => window.location.assign("https://bits2beat.com")} />
      <MenuItem label={"DÉCONNEXION"} action={() => authSvc.logout(navigate)} />
    </div>
  </div>;
}

export const DoctorMenu: React.FC<MenuProps> = (props: MenuProps) => {
  const navigate = useNavigate();
  const policySvc = ServiceRepository.getInstance().policySvc;
  const authSvc = ServiceRepository.getInstance().authSvc;

  return  <div className={'menu-container'} style={{visibility: props.isMenuOpened ? "visible" : "hidden"}} onClick={props.shouldCloseMenu}>
    <div className={"menu"}>
      <MenuItem label={"FERMER LE MENU"} action={props.shouldCloseMenu} />
      <MenuItem label={"MES PATIENTS"} action={() =>
        navigate(policySvc.policy.getLandingURL() + "?showProfile=false", {replace: true})
      } />
      <MenuItem label={"MON PROFIL"} action={() => {
        navigate(policySvc.policy.getLandingURL() + "?showProfile=true", {replace: true})
      }} />
      <MenuItem label={"À PROPOS"} action={() => window.location.assign("https://bits2beat.com")} />
      <MenuItem label={"DÉCONNEXION"} action={() => authSvc.logout(navigate)} />
    </div>
  </div>;
}

export const PatientMenu: React.FC<MenuProps> = (props: MenuProps) => {
  const navigate = useNavigate();
  const policySvc = ServiceRepository.getInstance().policySvc;
  const authSvc = ServiceRepository.getInstance().authSvc;

  return  <div className={'menu-container'} style={{visibility: props.isMenuOpened ? "visible" : "hidden"}} onClick={props.shouldCloseMenu}>
    <div className={"menu"}>
      <MenuItem label={"FERMER LE MENU"} action={props.shouldCloseMenu} />
      <MenuItem label={"FAIRE UN RELEVÉ"} action={() =>
        navigate(policySvc.policy.getLandingURL() + "?showProfile=false", {replace: true})
      } />
      <MenuItem label={"MON PROFIL"} action={() => {
        navigate(policySvc.policy.getLandingURL() + "?showProfile=true", {replace: true})
      }} />
      <MenuItem label={"À PROPOS"} action={() => window.location.assign("https://bits2beat.com")} />
      <MenuItem label={"DÉCONNEXION"} action={() => authSvc.logout(navigate)} />
    </div>
  </div>;
}
