export namespace DateUtils {
  export function now() : number {
    return Math.floor(Date.now() / 1000);
  }

  export function prettifyTimestamp(timestamp: number, includeHour?: boolean) : string {
    let date = new Date(timestamp * 1000);

    const dateWithZeros = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const monthWithZeros = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1);

    if (!includeHour) return `${dateWithZeros}.${monthWithZeros}.${date.getFullYear()}`;
    else return `${dateWithZeros}.${monthWithZeros}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
  }
}