import {User} from "../models/user";
import {Policy} from "../models/policy/policy";
import {AnonymousPolicy} from "../models/policy/anonymousPolicy";
import {PatientPolicy} from "../models/policy/patientPolicy";
import {DoctorPolicy} from "../models/policy/doctorPolicy";
import {AdminPolicy} from "../models/policy/adminPolicy";
import {UserService} from "./userService";
import {ListenableService, Listener} from "./serviceRepository";

export class PolicyService implements ListenableService<Policy>, Listener<User.DTO> {
  private _policy: Policy;
  private _listeners: Array<Listener<Policy>> = [];

  public get policy(): Policy {
    return this._policy;
  }

  constructor(_userSvc: UserService) {
    _userSvc.addListener(this);
    this._policy = this._getPolicyForRole(_userSvc.currentUser?.role);
    this._notifyListeners();
  }

  onSubjectUpdate(sub?: User.DTO): void {
    const newPolicy = this._getPolicyForRole(sub?.role);
    if (this._policy !== newPolicy) {
      this._policy = newPolicy
      this._notifyListeners();
    }
  }

  private _getPolicyForRole(role?: User.Role): Policy {
    if (!role) return new AnonymousPolicy();

    switch (role) {
      case User.Role.ANONYMOUS:
        return new AnonymousPolicy();
      case User.Role.PATIENT:
        return new PatientPolicy();
      case User.Role.DOCTOR:
        return new DoctorPolicy();
      case User.Role.ADMIN:
        return new AdminPolicy();
    }
  }

  public addListener(listener: Listener<Policy>): void {
    this._listeners.push(listener);
  }

  public removeListener(listener: Listener<Policy>): void {
    let index = this._listeners.indexOf(listener);
    if (index === -1) return;
    this._listeners.splice(index, 1);
  }

  private _notifyListeners(): void {
    for (let listener of this._listeners) {
      listener.onSubjectUpdate(this._policy);
    }
  }

  init() {
  }
}