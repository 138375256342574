import {Report} from "../models/report";
import {ProgressListener} from "../models/api/progressListener";
import {ServiceRepository} from "./serviceRepository";
import {WebcamStream} from "./webcamService";

export interface ReportControllerListener {
  //0 : 0%, 100: 100%
  recordingProgressDidUpdate: (remainingTime: number, progress: number) => void;
  timeIsUp: () => void;
  uploadProgressDidUpdate: (total: number, uploaded: number) => void;
}

export class ReportController {
  private _listener: ReportControllerListener;
  private _timeout?: NodeJS.Timeout = undefined;
  private _interval?: NodeJS.Timeout = undefined;
  private _refreshRate: number = 1000;
  private _refreshCount: number = 0;
  private _report?: Report.DTO;
  public progressListener?: ProgressListener;

  constructor(listener: ReportControllerListener) {
    this._listener = listener;
  }

  // //UPLOAD PROGRESS
  // public onProgress(progressEvent: ProgressEvent<EventTarget>) {
  //
  // };

  public startReport(report: Report.DTO) {
    console.log("startReport", report.id);
    // report.sDuration = 30
    this._report = report;
    if (this._timeout !== undefined) {
      clearTimeout(this._timeout);
      this._timeout = undefined;
    }
    this._timeout = setTimeout(() => {
      if (this._timeout) clearTimeout(this._timeout);
      if (this._interval) clearInterval(this._interval);

      console.log("time is up..", this._refreshCount);
      this._listener.timeIsUp();
    }, report.sDuration * 1000)
    // }, report.sDuration * 1000)

    if (this._interval !== undefined) {
      clearInterval(this._interval);
      this._interval = undefined;
    }
    this._interval = setInterval(() => {
      this._refreshCount++;
      this._listener.recordingProgressDidUpdate(
        (report.sDuration * 1000) - (this._refreshCount * this._refreshRate),
        (this._refreshCount / (report.sDuration * 1000 / this._refreshRate)) * 100
      );
    }, this._refreshRate);
  }

  public get started(): boolean {
    return this._timeout !== undefined;
  }

  public startUpload() {
    const reportSvc = ServiceRepository.getInstance().reportSvc;
    const webcamSvc = ServiceRepository.getInstance().webcamSvc;
    if (!this._report) {
      alert("Pas de report dispo..")
      return;
    }

    const controller = this;
    const report = this._report;

    const listener = {
      onSubjectUpdate(sub?: WebcamStream) {
        if (webcamSvc.webcamStream.blobs.length === 0) {
          console.info("Pas de blobs dispo..")
          return;
        }

        const blob = webcamSvc.webcamStream.blobs[0];
        webcamSvc.webcamStream.blobs = webcamSvc.webcamStream.blobs.splice(0, 1);
        console.log("addfile reportid", report.id);
        reportSvc.addFile(controller, report.id, blob, report.id + "-record").then(() => {
          console.info("file added");
        }).catch(e => {
          console.error(e);
        });
        webcamSvc.removeListener(listener);
      }
    };

    webcamSvc.addListener(listener);
    webcamSvc.stopRecording();
  }
}