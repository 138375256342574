import {Policy} from "./policy";
import {User} from "../user";
export class DoctorPolicy implements Policy {
  getLandingURL(): string {
    return "/doctor"
  }

  canAddReport(userAsking: User.DTO): boolean {
    return false;
  }

  canAddUser(userAsking: User.DTO, userToAdd: User.DTO): boolean {
    return false;
  }

  canDeleteReport(userAsking: User.DTO, reportId: string): boolean {
    return false;
  }

  canDeleteUser(userAsking: User.DTO, userId: string): boolean {
    return false;
  }

  canEditReport(userAsking: User.DTO, reportId?: string): boolean {
    return false;
  }

  canEditUser(userAsking: User.DTO, userId: string): boolean {
    return false;
  }

  canReadReport(userAsking: User.DTO, reportId?: string): boolean {
    return false;
  }

  canReadUser(userAsking: User.DTO, userId?: string): boolean {
    return false;
  }

  canReadFile(currentUser: User.DTO, key: string): boolean {
    return false;
  }
}