export namespace URLs {
  export namespace API {
    export const LOGIN = "/auth/login";
    export const REFRESH = "/auth/refresh";
    export const RESET_PASSWORD = "/auth/password/reset";
    export const LOGOUT = "/auth/logout";

    export const REPORTS = "/reports";
    export const REPORT = "/reports/:reportId";
    export const REPORT_CAPTURE = "/reports/:reportId/CAPTURE";

    export const USERS = "/users";
    export const USERS_DOCTORS = "/users?role=DOCTOR";
    export const USERS_PATIENTS = "/users?role=PATIENT";
    export const USER = "/users/:userId";
    export const CHANGE_PASSWORD = "/users/:userId/password";
    export const USER_REQUEST_CHANGES = "/users/requestChanges";
    export const USER_PASSWORD = "/users/:userId/password";
    export const SEND_ACCESS_USER = "/users/:userId/access";
    export const GET_CURRENT_USER_PROFILE = "/users/me";
  }

  export namespace Front {
    export const LOGIN = "/login"
    export const ROOT = "/"
    export const RESET_PASSWORD = "/password/reset/:token"
  }
}
