import React from "react";
import {Button} from "../Shared/Button/Button";
import "./PatientList.scss";
import {ServiceRepository} from "../../services/serviceRepository";
import {User} from "../../models/user";
import List from "../Shared/List/List";
import UsersDataProvider from "../../adapter/UsersDataProvider";
import PatientsAdapter, {PatientsAdapterListener} from "../../adapter/PatientsAdapter";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import searchImg from "assets/images/search.svg";

interface PatientListProps {
  navigate: NavigateFunction
  doctorId?: string
}

interface PatientListState {
  dataProvider: UsersDataProvider,
  adapter: PatientsAdapter
}

export default class PatientList extends React.Component<PatientListProps, PatientListState> implements PatientsAdapterListener {
  private readonly _userSvc = ServiceRepository.getInstance().userSvc;
  private _isMounted = false;

  constructor(props: PatientListProps, state: PatientListState) {
    super(props, state);
    const dataProvider = new UsersDataProvider(this._userSvc, User.Role.PATIENT, props.doctorId);

    this.state = {
      dataProvider: dataProvider,
      adapter: new PatientsAdapter(dataProvider, this)
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: Readonly<PatientListProps>, prevState: Readonly<PatientListState>, snapshot?: any) {
    if (prevProps.doctorId !== this.props.doctorId) {
      let {dataProvider} = this.state;
      dataProvider.referentUserId = this.props.doctorId;
    }
  }

  removeClicked = (user: User.DTO) => {

  };

  rowSelected = (obj: User.DTO) => {
    this.props.navigate(`/patients/${obj.id}`);
  }

  render() {
    const referentIdSearchParam = (this.props.doctorId ? `?doctorId=${this.props.doctorId}` : "")

    return <div className={"patient-list"}>
      <div className={"patient-list-header"}>
        <div className={"search-box"}>
          <input placeholder={"Rechercher un patient"}/>
          <img src={searchImg} alt='loupe'/>
        </div>
        <Button.Component
          color={Button.Color.LIGHT_BLUE}
          label={"CRÉER UN PATIENT"} onClick={() => this.props.navigate(`/patients/create/${referentIdSearchParam}`, {replace: true})}/>
      </div>
      <List adapter={this.state.adapter} dataProvider={this.state.dataProvider}/>
    </div>
  }
}