import React from "react";
import fullLogo from 'assets/images/logo_baseline_bleu_transparent.png';
import {Button} from "../Shared/Button/Button";
import "./Root.scss"
import {useNavigate} from "react-router-dom";

const Root: React.FC = () => {
  const navigate = useNavigate();

  return <div className={"root-container"}>
    <img src={fullLogo} className={"logo"} alt={"b2b logo"}/>
    <div className={"btn-container"}>
      <Button.Component
        onClick={() => navigate("/login", {replace: true})}
        color={Button.Color.LIGHT_BLUE}
        label={"Connexion"}
      />
      <a href={"https://bits2beat.com"} target={"_blank"} rel="noreferrer">
        <Button.Component
          color={Button.Color.CLEAR}
          label={"À propos"}
        />
      </a>
    </div>
  </div>;
}
export default Root;